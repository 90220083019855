import React, { useEffect, useState } from "react";
import "../../../App.css";
import {
  RecruitmentSteps,
  ScreenFlow,
  Loading,
  Map,
  DataNotFound,
} from "../../../components";
import ShowRequirements from "../enrollment requirements/EnrollmentRequirements";
import { getLocations } from "../../../helpers";
import { saveButton, greenDot } from "../../../features/theme/icons";
import moment from "moment";
import { environment, Google_Map_Key } from "../../../constants";
import { log } from "../../../helpers/helper";
import PromoTrials from "../../promo trials";

const PSView = (props) => {
  const {
    isLoading,
    studyId,
    trial,
    showRequirements,
    zipcode,
    coordinates,
    queryCountry,
    queryCondition,
    hideSteps,
    showMultipleSites,
    multipleSites,
    onCheckboxChange,
    userPickedStudyId,
    areCoordinatesReceived,
  } = props;

  const flowarray = [
    { title: "Home", link: "/" },
    { title: "Find Trials", link: "/#find_trial" },
    {
      title: "Search Results",
      link: `/results?q=${queryCondition}&in=${queryCountry}`,
    },
    {
      title: "Trial Details",
      link: `/trialdetails/${studyId}?q=${queryCondition}&in=${queryCountry}`,
    },
  ];
  const [map, setMap] = useState(false);

  useEffect(() => {
    if (trial === undefined || trial === null || trial === "") {
      //log("trial not found");
    } else {
      if (multipleSites && multipleSites.length > 0) {
        // getMultipleLocations(multipleSites).then(gotLocations=>{
        //   let locations_list = gotLocations
        //     setCoordinates(locations_list)
        setTimeout(() => {
          setMap(true);
        }, 1500);
        // }).catch(errLocations=>{
        //   log(errLocations,'error with geocoding')
        // })
      }
    }
  }, []);

  const TrialDetails = () => {
    const {
      active,
      promote,
      brief_summary,
      eligibility,
      detailed_description,
      site_name,
      city,
      status,
      country,
      state,
      street_address,
      sys_updated_on,
      snow_study_number,
      study_sys_id,
    } = trial;
    return (
      <div className="container p-0">
        <div className="trialdetails row">
          <div className="col-sm-12 col-md-9 col-lg-9 px-1">
            {hideSteps === true ? null : (
              <div className="mt-2 d-flex align-items-start justify-content-between">
                <ScreenFlow flow={flowarray} />
                {/* <img src={saveButton} alt="save icon"></img> */}
              </div>
            )}
            <div>
                <p className="updated_date mb-0 px-1">
                  Last updated on{" "}
                  {moment(sys_updated_on).format("MMMM Do YYYY")}
                </p>
              </div>
            <div className="mt-1 d-flex align-items-start justify-content-between">
              <div className="px-1">
                {/* <img className="mx-2" src={greenDot} alt="greendot"></img> */}
                <span>
                  {trial && trial.study_phase && trial.study_phase.length > 0
                    ? trial.study_phase
                    : trial && trial.nct_number && trial.nct_number.length > 0
                    ? trial.nct_number
                    : "None"}
                </span>
                <span className="mx-1">{"|"}</span>
                <span>
                  {trial && trial.conditions && trial.conditions.length > 0
                    ? trial.conditions
                    : trial && trial.study_name && trial.study_name.length > 0
                    ? trial.study_name && trial.study_name.length > 50
                      ? trial.study_name.slice(0, 50) + "..."
                      : trial.study_name
                    : "None"}
                </span>
              </div>
            </div>
            <div className="details m-1">
              <span>
                {trial && trial.brief_title && trial.brief_title.length > 0
                  ? trial.brief_title
                  : "None"}
              </span>
              {/* <h3 className="py-4">
                Clinical Study Identifier:{" "}
                <span className="mx-4" style={{ color: "#034EA2" }}>
                  {studyId}
                </span>
              </h3> */}
              {showRequirements ? (
                <ShowRequirements
                  criteria={eligibility}
                  city={city}
                  state={state}
                  country={country}
                  zipcode={zipcode}
                  sysId={snow_study_number}
                  queryCountry={queryCountry}
                  queryCondition={queryCondition}
                  studySysId={study_sys_id}
                />
              ) : (
                <div>
                  <div className="row my-4">
                  <div className="goal-sponsor col-sm-12 col-md-6 col-lg-4 ml-0 pl-0 text-left">
                      <h2>Clinical Study Identifier</h2>
                      <p
                        style={{
                          color: "#034EA2",
                          //"text-decoration": "underline",
                        }}
                      >
                        {studyId}
                      </p>
                    </div>
                    <div className="goal-sponsor col-sm-12 col-md-6 col-lg-4 ml-0 pl-0 text-left">
                      <h2>Site Name</h2>
                      <p
                        style={{
                          color: "#034EA2",
                          //"text-decoration": "underline",
                        }}
                      >
                        {site_name}
                      </p>
                    </div>
                    <div className="goal-sponsor col-sm-12 col-md-6 col-lg-4 ml-0 pl-0 text-left">
                      <h2>Trial Status</h2>
                      <p
                        style={{
                          color: "#034EA2",
                          //"text-decoration": "underline",
                        }}
                      >
                        {status}
                      </p>
                    </div>
                  </div>
                  {/* {showMultipleSites === true &&
                  multipleSites &&
                  multipleSites.length > 0 ? (
                    <div className="purpose-timeframe">
                      <h2 style={{ fontWeight: "bold" }} className="py-1">
                        Locations
                      </h2>
                      {multipleSites.map((multisite, i) => (
                        <div key={i} className="promo_answer">
                          <input
                            className="mx-1 mt-2"
                            type="radio"
                            onChange={(e) => onCheckboxChange(e)}
                            name={multisite.snow_study_number}
                            value={multisite.snow_study_number}
                            checked={
                              userPickedStudyId === multisite.snow_study_number
                            }
                          />
                          <span className="mx-2">{`${multisite.street_address}, ${multisite.city}, ${multisite.state}, ${multisite.country}, ${multisite.pin_code}`}</span>
                        </div>
                      ))}
                    </div>
                  ) : null} */}
                  <div className="purpose-timeframe">
                    <h2 style={{ fontWeight: "bold" }} className="py-1">
                      Purpose
                    </h2>
                    <p style={{color: '#66676B'}} className="py-1">{brief_summary}</p>
                  </div>
                  <div className="purpose-timeframe">
                    <h2 style={{ fontWeight: "bold" }} className="py-1">
                      Description
                    </h2>
                    <p style={{color: '#66676B'}} className="py-1">{detailed_description}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          {showRequirements === false ? (
            <div style={{backgroundColor : '#F9F9F9'}} className="closestlocation h-75 col-sm-12 col-md-3 col-lg-3 p-4 mt-5 text-left">
              <h2>THE CLOSEST LOCATION</h2>
              <h3>{site_name}</h3>
              <h2>{street_address + " , " + city + " , " + state}</h2>
              <div className="text-center">
                {
                  showRequirements ? (
                    <div
                      // style={{
                      //   cursor: "pointer",
                      //   backgroundColor: showRequirements
                      //     ? "#BDBDBD"
                      //     : "#BC5405",
        
                      // }}
                    >
                      <button
                        onClick={() =>
                          showRequirements ? null : props.onEnrollForLocation()
                        }
                        //style={{ backgroundColor: "#BDBDBD", border: "none" }}
                        className="dt_btn text-center btn btn-lg"
                        disabled={true}
                      >
                        Enroll
                      </button>
                    </div>
                  ) : (
                    // disableEnrollButton ?
                    // (
                    //   <div
                    //     style={{
                    //       cursor: "pointer",
                    //       backgroundColor: "#BDBDBD",
                    //       borderRadius: "3px",
                    //     }}
                    //   >
                    //     <button
                    //       onClick={() =>
                    //         showRequirements ? null : props.onEnrollForLocation()
                    //       }
                    //       style={{ backgroundColor: "#BDBDBD", border: "none" }}
                    //       disabled={true}
                    //       className="enroll text-center btn btn-lg"
                    //     >
                    //       Sign Up
                    //     </button>
                    //   </div>
                    // )
                    // :
                    // (
                    <div>
                      <button
                        style={{
                          cursor: "pointer",
                          backgroundColor: (active === "true" && promote === "true") ? "#012d61" : "dimgrey",
                          color : 'white',
                          borderRadius: "3px",
                        }}
                        disabled = {(active === "true" && promote === "true") ? false : true}
                        onClick={() =>{
                          //if((active === "true" && promote === "true")){
                            (window.location.href = `/enroll/${
                              userPickedStudyId && userPickedStudyId.length > 0
                                ? userPickedStudyId
                                : snow_study_number
                            }`)
                          //}
                        }
                        }
                        className={`dt_btn text-center btn btn-lg col-12 ${(active === "true" && promote === "true") ? "" : "no_hover"}`}
                      >
                        Enroll
                      </button>
                      {/* <a href={`#map`}>
                        <p className="mb-0 mt-1">Check below map for other locations</p>
                      </a> */}
                    </div>
                  )
                  // )
                }
                {/* <p className="my-2">View all Locations</p> */}
              </div>
              {/* <div className="notification text-left">
              <h2>GET NOTIFIED</h2>
              <br></br>
              <h2>
                Sign up to receive email notification when clinical trials are
                posted in the medical category of interest to you.{" "}
              </h2>
              <div style={{ border: "1px solid #E57D24", borderRadius: "3px" }}>
                <a
                  href="#"
                  className="turn-on-notification d-flex align-items-center justify-content-center border rounded btn btn-lg"
                >
                  TURN ON NOTIFICATION
                </a>
              </div>
            </div> */}
            </div>
          ) : null}
        </div>
        { environment === "PROD" ? null : 
        <div id="map" className="container my-3">
        {areCoordinatesReceived === true ? (
          <Map
            isMarkerShown={true}
            position={coordinates}
            showMap={areCoordinatesReceived}
            toEnroll={true}
            containerElement={<div style={{ height: `400px` }} />}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${Google_Map_Key}`}
            loadingElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        ) : null}
      </div>
        }
      </div>
    );
  };

  return (
    <div>
      {hideSteps === true ? null : <RecruitmentSteps step={"2. Review Trials"} />}
      {isLoading ? (
        trial === null || trial === undefined ? (
          <DataNotFound message={"No Clinical Trial Found"} />
        ) : (
          trial && trial.embedded_link && trial.embedded_link.length > 0 ?
          <PromoTrials nctid={trial && trial.nct_number} />
          :
          <TrialDetails hideSteps={hideSteps} />
        )
      ) : (
        <Loading />
      )}
    </div>
  );
};
export default PSView;
