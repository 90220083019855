import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";
import PromoTrials from "../../features/promo trials";
import { HomeController } from "../home";
import { ResultsController } from "../scope";
import { ReviewTrialController } from "../review trials";
import {PtEnrollScreen} from "../review trials/trialdetails"
import { EnrollmentController, ThankYou } from "../enroll";
import { DataNotFound, Footer, Header, Loading } from "../../components";
import { Blogs } from "../blogs";
import { Domain, environment } from "../../constants";
import Preview from "../preview trials/preview";
import Kira from "../../components/kira";
import Mosaic from "../../components/mosaic";
import Vlu from "../../components/vlu";
import EMR from "../../components/Emr";
import EHR from "../../components/Ehr";
import Conference from "../../components/Conference";
import ConnectEMR from "../../components/ConnectToEMR";
import ConferenceCheck from "../../components/ConferenceCheck";
import Records from "../../components/Records";
import Agent from "../../components/Agent";
import DocumentUpload from "../../components/DocumentUpload";
import DocumentEnrollment from "../enroll/DocumentEnrollmentController";
import Navigate from "../../components/Navigate"
import Concierge from "../../components/Concierge";
import Nurse from "../../components/Nurse";
import { chat_bot_icon, chat_human_icon, gen_chat, template2age } from "../theme/icons";
import { send_user_message } from "../../helpers/pthelper";
import { log } from "../../helpers/helper";


//import Test from "../../components/Test";

class Navigation extends React.Component {
  constructor(props){
    super();
    this.state={
      enableChat : false,
      showChat : false,
      chat_intput : "",
      context : "",
      messages : []
    }
  }

  get_main_terms = (terms, words) =>{
    if((terms !== undefined) && (terms && terms.length > 0)){
      return terms
    } else if((words !== undefined) && (words && words.length > 0)){
      return words
    } else {
      return []
    }
  }

  sendMessaage=()=>{
    this.fetchReplies()
    let input_message = this.state.chat_intput;
    if(input_message && input_message.length > 0){
      let message = {
        from : "user",
        text : this.state.chat_intput,
        created : Date.now()
      }
      if(this.state.messages && this.state.messages.length > 0){
        let replies = this.state.messages;
        let modified_replies = replies.concat([message])
        localStorage.setItem("messages",JSON.stringify(modified_replies))
        this.setState({chat_intput : ""},()=>{
          send_user_message(input_message, this.state.context).then(got_reply=>{
            log(got_reply,'got reply');
            let main_terms = got_reply && got_reply.main_terms
            let keywords = got_reply && got_reply.keywords
            let context = this.get_main_terms()
            let bot_message = {
              from : "bot",
              text : got_reply && got_reply.answer != undefined ? got_reply.answer : "Sure, let me take you to the trials related to "+ main_terms && main_terms.length > 0 ? main_terms.join(",") : keywords[keywords.length - 1]
            }
            this.fetchReplies()
            let existing_chat = this.state.messages
            let conversation = existing_chat.concat([bot_message])
            localStorage.setItem("messages",JSON.stringify(conversation))
            this.fetchReplies()
            if(main_terms && main_terms.length > 0){
              window.location.href = `results?q=${main_terms.join(",")}&in=UNITED%20STATES`
            }else if(keywords && keywords.length > 0){
              window.location.href = `results?q=${keywords[keywords.length - 1]}&in=UNITED%20STATES`
            }
          }).catch(errReply=>{
            log(errReply,'err reply')
          })
        })
      }else{
        localStorage.setItem("messages",JSON.stringify([message]))
        this.setState({chat_intput : ""},()=>{
          send_user_message(input_message, this.state.context).then(got_reply=>{
            log(got_reply,'got reply');
            let main_terms = got_reply && got_reply.main_terms
            let keywords = got_reply && got_reply.keywords
            let context = this.get_main_terms()
            let bot_message = {
              from : "bot",
              text : got_reply && got_reply.answer != undefined ? got_reply.answer : "Sure, let me take you to the trials related to "+ main_terms && main_terms.length > 0 ? main_terms.join(",") : keywords[keywords.length - 1]
            }
            this.fetchReplies()
            let existing_chat = this.state.messages
            let conversation = existing_chat.concat([bot_message])
            localStorage.setItem("messages",JSON.stringify(conversation))
            this.fetchReplies()
            if(main_terms && main_terms.length > 0){
              window.location.href = `results?q=${main_terms.join(",")}&in=UNITED%20STATES`
            }else if(keywords && keywords.length > 0){
              window.location.href = `results?q=${keywords[keywords.length - 1]}&in=UNITED%20STATES`
            }
          }).catch(errReply=>{
            log(errReply,'err reply')
          })
        })
      }
    }else{
      log(input_message,'no user input')
      this.setState({chat_intput : ""})
    }
    this.fetchReplies()
  }

  fetchReplies = () =>{
    let get_messages = localStorage.getItem("messages");
    if(get_messages !== null){
      this.setState({messages : JSON.parse(get_messages)})
    }else{
      this.setState({messages : []})
    }
  }

  componentDidMount(){
    // log(this.props,'url')
    let fetch_chat = window.localStorage.getItem("enableChat")
    log(fetch_chat, typeof(fetch_chat) ,'study chat status')
    this.fetchReplies()
    setTimeout(()=>{
      if((fetch_chat !== null) && (fetch_chat === "true")){
        this.setState({enableChat : true})
      } else if (fetch_chat === "false"){
        this.setState({ enableChat : false})
      }
    },1000)
  }

  displayChat=(status)=>{
    this.setState({showChat : status})
  }
  textChange = (e) =>{
    this.setState({chat_intput : e.target.value})
  }
  controlChat=(status)=>{
    this.setState({enableChat : status},()=>{
      window.localStorage.removeItem("messages")
    window.localStorage.setItem("enableChat", false)
    })
  }

  addContext=(context)=>{
    this.setState({context : context})
  }

  render() {
    return (
      <div>
        {this.props.location && this.props.location.search && this.props.location.search.indexOf("embed=true") !== -1 ? <Header showHeader={false} /> : <Header />}
        <Switch>
          <Route exact path="/" component={HomeController} />
          <Route exact path="/signup">
            <Redirect to={environment === "PROD" ? "/enroll/STU01009" : "/enroll/STU0001002"}></Redirect>
          </Route>
          <Route exact path="/participate">
            <Redirect to={environment === "PROD" ? "/enroll/STU01009" : "/enroll/STU0001002"}></Redirect>
          </Route>
          <Route exact path={`/results/`} component={ResultsController} />
          <Route exact path="/demo" component={Navigate} />
          <Route exact path="/KIRA" component={Kira} />
          <Route exact path="/kira" component={Kira} />
          <Route exact path="/mosaic" component={Mosaic} />
          <Route exact path="/MOSAIC" component={Mosaic} />
          <Route exact path="/vlu" component={Vlu} />
          <Route exact path="/VLU" component={Vlu} />
          <Route exact path="/concierge" component={Concierge} />
          <Route exact path="/nurse" component={Nurse} />
          <Route exact path="/medical-records/:prospect/:city" component={EMR} />
          <Route exact path="/emr" component={EHR} />
          <Route exact path="/agent" component={Agent} />
          <Route exact path="/upload-document/:studyId" component={DocumentUpload} />
          <Route exact path ="/upload-enroll/:studyId" component={DocumentEnrollment} />
          <Route exact path="/connect-to-emr/:randomID/:study_sys_id/:domain_id/:study_number" component={ConnectEMR} />
          <Route exact path="/health-records/:randomID/:study_sys_id/:domain_id/:study_number" component={Records} />
          {/* <Route exact path="/test" component={Test} /> */}
          <Route
            exact
            path={"/study/:studyId"}
            // component={ReviewTrialController}
            render={() => <ReviewTrialController addContext={this.addContext} />}
          />
          
          <Route exact path={`/thankyou/:pt`} component={ThankYou} />
          <Route exact path={`/upcoming-research/`} component={PtEnrollScreen} />
          <Route exact path={`/loading/`} component={Loading} />
          <Route path={`/enroll/:studyId`} component={EnrollmentController} />
          {/* <Route exact path={"/study/:nctid"} component={PromoTrials} /> */}
          <Route exact path={"/preview/:studyId"}  render={() => <Preview addContext={this.addContext}  />} />
          <Route exact path={"/conference/:patient/:appointmentId"} component={Conference} />
          <Route exact path={"/conference-check/:patient/:appointmentId"} component={ConferenceCheck} />
          {/* <Route exact path={"/blog/:blogid"} component={Blogs} />
          <Route exact path={"/blog"} component={Blogs} /> */}
          <Route component={DataNotFound} />
        </Switch>
        {this.state.enableChat === true ? 
          <div className="chat d-flex align-items-center justify-content-end">
              {
                this.state.showChat === true ? 
                <div style={{position: 'fixed',bottom :110,right : 20,height: 400, width : 400, borderRadius : 10, backgroundColor:'#F0F0F0'}}>
                    <div style={{height:50, width: '100%', backgroundColor:'#034ea2', color : 'white', borderRadius: 7}} className="d-flex align-items-center justify-content-between">
                        <h6 className="py-1 px-2">Study Assistant</h6>
                        <div className="d-flex align-items-center justify-content-center">
                            <h6 onClick={()=>this.displayChat(false)} style={{fontSize: 35, cursor : 'pointer'}} className="py-1 px-2"><strong>-</strong></h6>
                            <h6 onClick={()=>this.controlChat(false)} style={{fontSize: 20, cursor : 'pointer'}} className="py-1 px-2"><strong>x</strong></h6>
                        </div>
                    </div>
                    <div style={{height: 305, overflowY: "scroll", opacity:15, backgroundColor :'white'}}>
                      {this.state.messages && this.state.messages.length > 0 ? 
                        <div className="p-2">
                          {this.state.messages.map(message => 
                            message.from === "user" ? 
                            <div style={{backgroundColor : '#034ea2',width : 'fit-content', borderRadius: 7,}} className="d-flex align-items-center justify-content-end p-2 ml-auto">
                                <img style={{height:40}} src={chat_human_icon} alt="user"></img>
                                <div style={{color:'white', fontSize:23, fontFamily:'LatoRegular'}}  className="d-block align-items-center justify-content-end p-2">
                                  <p style={{fontSize : 13, fontWeight: "normal",  color : 'white'}} className="mb-0">{"User"}</p>
                                  <h6 style={{fontSize : '1.2rem'}}>{message.text}</h6>
                                </div>  
                            </div>
                            :
                            <div style={{backgroundColor : '#f9f9f9', width : 'fit-content', borderRadius: 7}} className="d-flex align-items-center justify-content-start p-2">
                                <img style={{height:40}} src={chat_bot_icon} alt="bot"></img>
                                <div style={{color:'#012d61', fontSize:23, fontFamily:'LatoRegular'}}  className="d-block align-items-center justify-content-start p-2">
                                  <p style={{fontSize : 13, fontWeight: "normal",  color : '#012d61'}} className="mb-0">{"Study Assistant"}</p>
                                  <h6 style={{fontSize : '1.2rem'}}>{message.text}</h6>
                                </div>  
                            </div>
                          )}
                        </div>
                      : 
                      <div className="d-flex align-items-center justify-content-center p-5">
                          <div className="d-block align-items-center justify-content-center">
                            <img className="ml-5" style={{height:120}} src={chat_bot_icon} alt="bot"></img>
                            <p>Hi, welcome to PatienTrials Study Assistant, I can help you with your queries such as trials based on condition or NCT identifier</p>
                          </div>
                      </div>}
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <input className="p-2" style={{height: 35, width: 320, border: '1px solid #034ea2',fontSize: 20, outline: 'none'}} type="text" name="chat_input" value={this.state.chat_intput} onChange={this.textChange} placeholder="Enter your message!"></input>
                        <button style={{height : 35, width : 70, backgroundColor: '#034ea2', color:'white', cursor: 'pointer', outline: 'none'}} onClick={()=>{this.sendMessaage()}}>Send</button>
                    </div>
                </div>
                : null
              }
              <div onClick={()=>{this.displayChat(true)}} style={{cursor : 'pointer',position: 'fixed',bottom :20,right : 20,height:70, width:70, borderRadius: 35, backgroundColor:'#034ea2'}} className="d-flex align-items-center justify-content-center">
                  <img style={{height:25, width:30}} src={gen_chat} alt="chat_logo"></img>
              </div>
          </div> : null}
        {this.props.location && this.props.location.search && this.props.location.search.indexOf("embed=true") !== -1 ? <Footer showFooter={false} /> : <Footer />}
      </div>
    );
  }
}

export default withRouter(Navigation);
