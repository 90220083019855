import React, { useEffect } from 'react' ; 
import { withRouter } from 'react-router-dom';
import ChatScript from '../features/enroll/chat';
import { fetchRecords, updateProspectStatus } from '../helpers/pthelper';
import { log } from '../helpers/helper';

const EHR = () =>{

    useEffect(()=>{
        let user = window.localStorage.getItem('user');
        let city = window.localStorage.getItem('city');
        let dob = window.localStorage.getItem('dob');
        let gender = window.localStorage.getItem('gender');
        let emr_user = window.localStorage.getItem('emr_user')
        let domain_id = window.localStorage.getItem('domainID')
        let study_number = window.localStorage.getItem('study_number')
        let study_sys_id = window.localStorage.getItem('studyID')
        let user_sysId = window.localStorage.getItem('user_sysId')
        log(user, ' got user');
        if(user !== null){
            window.localStorage.setItem("after-records-status","fetched")
            window.location.href = `/medical-records/${user}/${city}?dob=${dob}&gender=${gender}`
            // updateProspectStatus(true , user , user_sysId).then(update=>{
            //     log(update, 'updated');
            //     window.location.href = `/medical-records/${user}/${city}?dob=${dob}&gender=${gender}`
            // }).catch(errUpdate=>{
            //     log(errUpdate , 'err while updating')
            // })
        } else if(emr_user !== null){
            window.localStorage.setItem("records-status","fetched")
            window.location.href = `/connect-to-emr/${emr_user}/${study_sys_id}/${domain_id}/${study_number}`
        } 
        else{
            log(user, 'no user found');
            alert("Error occured while fetching details of user")
        }
    },[])

return (
    <div className='my-5 py-5 container'>
        <ChatScript />
        <div className='d-flex align-items-center justify-content-center mt-5 pt-5'>
        <h6>Thank you. Please wait ...</h6>
        </div>
    </div>
)
}

export default withRouter(EHR)