import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  fetchVirtualAppointments,
  getConferenceStatus,
} from "../helpers/pthelper";
import Loading from "./Loading";
import { Redirect, useParams } from "react-router-dom";
import * as url from "url";
import { log } from "../helpers/helper";



const ConferenceCheck = () => {
  const { patient, appointmentId } = useParams();
  const [patient_join_link, set_patient_join_link] = useState("");
  const [checkForMeeting, setCheckForMeeting] = useState("");
  const [loading, setLoading] = useState(false);

  const getProspectName = (link) =>{
    if(link && link.length > 0){
      let splitName = link && link.split("?fullName=");
      log(splitName , 'splitName')
      if(splitName && splitName.length > 0){
        let name = splitName[1].split("&");
        return name && name.length > 0 ? name[0] : "Anonymous"
      }
    } else{
      log(link , 'no link found while fetching name');
      return "Anonymous"
    }
  }

  useEffect(() => {
    let conf_link = window.location.href;
    let parseUrl = url.parse(conf_link , true);
    let query = parseUrl.query
    log(query , 'checking url query')
    let statusLink = query.status_link
    let checksum = query && query.checksum;
    if(checksum !== undefined){
      let statusLinkChecksum = `${statusLink}&checksum=${query.checksum[1]}`
    let patientLink = query.join_link
    let patientLinkChecksum = `${patientLink}&meetingID=${query.meetingID}&password=${query.password}&guest=${query.guest}&checksum=${query.checksum[0]}`
    log(patientLinkChecksum , 'pt link')
    set_patient_join_link(patientLinkChecksum);
    if((patientLinkChecksum && patientLinkChecksum.length > 0) && ( statusLinkChecksum && statusLinkChecksum.length > 0 )){
    setInterval(() => {
        getConferenceStatus(statusLinkChecksum)
          .then((status) => {
            log(status, "succ");
            let result = status.response;
            let code = result.returncode[0];
            let conf_status = result.running[0];
            if (conf_status !== undefined) {
              setCheckForMeeting(
                (conf_status === "false" && false) ||
                  (conf_status === "true" && true)
              );
            } else {
              setCheckForMeeting(null);
            }
          })
          .catch((errStatus) => {
            log(errStatus, "err fetching status");
          });
      }, 4000);
      setLoading(true);
    } else{
      log(statusLinkChecksum , patientLinkChecksum , 'error receving links');
      setLoading(true);
      setCheckForMeeting(null)
      alert("Error connecting to conference")
    }
    } else{
      log(checksum , 'error receving checksum');
      setLoading(true);
      setCheckForMeeting(null)
      alert("Error connecting to conference")
    }
  }, []);

  const goToMeeting = () => {
    if(patient_join_link && patient_join_link.length > 0){
      log(patient_join_link);
      //alert(patient_join_link);
      window.location.href = patient_join_link
    } else{
      log(patient_join_link , 'pt link')
      setCheckForMeeting(null)
      alert("Error connecting to conference")
    }
  };

  return (
    <div className="my-5 py-5 mx-auto text-center">
      <h6 className="mt-4">Conference Waiting Room</h6>
      {loading === false ? (
        <Loading />
      ) : (
        <div>
          {/* <h6>{appointment.name || "Anonymous"}</h6> */}
          <p style={{ fontWeight : 'bold' }} className="mb-0">{patient}</p>
          <p style={{ fontWeight : 'bold' }} className="mb-0">{getProspectName(patient_join_link)}</p>
          {checkForMeeting === true ? (
            goToMeeting()
          ) : (
            <h4 className="mb-0 mt-5 pt-5">
              {(checkForMeeting === "" && "Loading ...") ||
                (checkForMeeting === null &&
                  "Meeting status cannot be determined") ||
                (checkForMeeting === false &&
                  "Please wait staff member needs to start the conference") ||
                (checkForMeeting === true &&
                  "Meeting started ... Re directing")}
            </h4>
          )}
        </div>
      )}
    </div>
  );
};

export default ConferenceCheck;

// 'https://wellcheck.patientrials.com/bigbluebutton/api/join?fullName=theasmoak&meetingID=068112831b0c951078b1315bcd4bcbb6@$$STU0001002&guest=true&password=PT002589&checksum=0e407849a1fff26ccb32eee7c2104374a91c5948'