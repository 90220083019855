import React from "react";
import { environment } from "../constants";
import { log, validateEmail } from "../helpers/helper";
import { fetchRandomRecords , createFile } from "../helpers/pthelper";
import Loading from "./Loading";
import ParticipantModal from "./ParticipantModal";
import axios from "axios";


class Records extends React.Component{
    constructor(props){
        super();
        this.race={};
        this.state={
            user_id : "",
            nctid : "" ,
            showModal : false,
            study_sys_id : "",
            records : "",
            studyId : "",
            isLoading : false,
            loadingForModal : false,
            loadingForParticipantModal : false,
            showParticipantModal : false,
            showAcknowledgement :false,
            patientSysId: "",
            country:"",
            city:"",
            zipcode:"",
            firstname:"",
            lastname:"",
            email:"",
            gender:"",
            dob:"",
            domain : "",
            phone:"",
            smokingstatus:"",
            primarylanguage:"",
            race:"",
            patient_id:"",
            currentmedication:"",
            healthcondition:"",
            familyhistory:"",
            showModal : false,
            recordsShared : false,
            recaptcha_response : "",
        }
    }

    updateAcknowledgement = () =>{
      axios
        .post("/backend/acknowledge", {
          sysId: this.state.patientSysId,
          acknowledged: "true",
        })
        .then((successAck) => {
          //log(`${successAck && successAck.data}, "on acknowledge"`);
          this.setState({
            isParticipantEnrolled: true,
            showModal: false,
            showAcknowledgement: false,
          },()=>{
            
            window.localStorage.setItem("chat_status" , "started");
          window.localStorage.setItem("prospectID" , this.state.patient_id );
          window.localStorage.setItem("f_name" , this.state.firstname );
          window.localStorage.setItem("city" , this.state.city);
          window.localStorage.setItem("dob" , this.state.dob );
          window.localStorage.setItem("gender" , this.state.gender );
          window.location.href = `/thankyou/${this.state.patient_id}?pre-connect=true`
          });
          
        })
        .catch((errSuccessAck) => {
          //log(`${errSuccessAck}, "error while acknowledgement"`);
          this.setState({
            isParticipantEnrolled: false,
            showModal: false,
            showAcknowledgement: false,
          },()=>{
            
          });
        });
    }

    shareRecords = () => {
      this.setState({ isLoading: false });
      // try {
      //   let result = await 
        createFile(
          this.state.patient_id,
          this.state.records,
          this.state.dob,
          this.state.gender,
          this.state.nctid
        ).then(result =>{
          log(result, "result");
        this.updateAcknowledgement()
        if (result.error === false) {
          this.setState(
            {
              recordsShared: true,
              isLoading: true,
              healthSystems: "",
            },
            () => {
              window.scrollTo(0, 0);
            }
          );
          window.localStorage.removeItem("user");
          window.localStorage.removeItem("city");
          window.localStorage.removeItem("dob");
          window.localStorage.removeItem("gender");
          window.localStorage.removeItem("nctId");
          window.localStorage.removeItem("chat_status");
          window.localStorage.removeItem('acc_sid')
          window.localStorage.removeItem("pt_sys_id");
          window.localStorage.removeItem('flow_id')
          window.localStorage.removeItem("study_nct")
          window.localStorage.removeItem("study_sid")
          window.localStorage.removeItem("prospectID");
        } else {
          window.localStorage.removeItem("user");
          window.localStorage.removeItem("city");
          window.localStorage.removeItem("dob");
          window.localStorage.removeItem("gender");
          window.localStorage.removeItem("nctId");
          window.localStorage.removeItem("chat_status");
          window.localStorage.removeItem('acc_sid')
          window.localStorage.removeItem("pt_sys_id");
          window.localStorage.removeItem('flow_id')
          window.localStorage.removeItem("study_nct")
          window.localStorage.removeItem("study_sid")
          window.localStorage.removeItem("prospectID");
        }
        }).catch(err=>{
          log(err, "err with file");
          this.updateAcknowledgement()
        this.setState({ recordsShared: false, isLoading: true });
        alert("Error while sharing records");
        window.localStorage.removeItem("user");
        window.localStorage.removeItem("city");
        window.localStorage.removeItem("dob");
        window.localStorage.removeItem("gender");
        window.localStorage.removeItem("nctId");
        window.localStorage.removeItem("chat_status");
        window.localStorage.removeItem('acc_sid')
        window.localStorage.removeItem("pt_sys_id");
          window.localStorage.removeItem('flow_id')
          window.localStorage.removeItem("study_nct")
          window.localStorage.removeItem("study_sid")
        window.localStorage.removeItem("prospectID");
        })
    };

    onRaceCheckboxChange = (e, qid) => {
      if (e.target.checked) {
        //log(`${e.target.checked}, ${e.target.value}, "checking race inputs"`);
        if (e.target.type === "checkbox") {
          this.race.hasOwnProperty("race")
            ? this.race["race"].push(e.target.value)
            : (this.race["race"] = [e.target.value]);
        } else {
          this.race.hasOwnProperty("race")
            ? this.race["race"].push(e.target.value)
            : (this.race["race"] = [e.target.value]);
        }
      } else {
        if (e.target.type === "checkbox") {
          //log(`${e.target.checked}, ${e.target.value}, "checking race inputs"`);
          if (this.race.hasOwnProperty("race")) {
            let arr = this.race["race"].indexOf(e.target.value);
            this.race["race"].splice(arr, 1);
          }
        }
      }
      //log(`${this.race}, "in race oncheckbox"`);
      this.setState({ race: this.race["race"].toString() });
    };

    onTextInputChange = (e) => {
      //log(`${e.target.name}, ${e.target.value}, ${e.target}`);
      this.setState({ [e.target.name]: e.target.value }, () => {
      if (
          this.state.email !== null ||
          this.state.email !== "" ||
          this.state.email !== undefined
        ) {
          let checkEmail = validateEmail(this.state.email);
          if (checkEmail === false) {
            this.setState({ showEmailError: true });
          } else if (checkEmail === true) {
            this.setState({ showEmailError: false });
          }
        }
      })
    };

    onAcknowledge = () => {
      this.shareRecords()
    };

    setCountry =(country) =>{
      if(country && country.length > 0){
        if((country === "US") || 
      (country === "USA") || 
      (country === "U.S") || 
      (country === "U.S.A") || 
      (country === "U.S.A.") || 
      (country === "United States of America") ||
      (country === "United States") || 
      (country === "us") || 
      (country === "usa") || 
      (country === "u.s") || 
      (country === "u.s.a") || 
      (country === "u.s.a.") || 
      (country === "united states of america") ||
      (country === "united states")){
        return "UNITED STATES"
      } else {
        return country
      }
      } else{
        return ""
      }
    }

    setLanguage = (language) =>{
      if(language && language.length > 0){
        if((language === "ENGLISH") || 
      (language === "english") || 
      (language === "EN") || 
      (language === "en") || 
      (language === "ENG") || 
      (language === "eng")){
        return "en"
      } else if(
        (language === "SPANISH") || 
        (language === "spanish") || 
        (language === "SP") || 
        (language === "sp") 
        ){
        return "sp"
      } else if((language === "VIETNAMESE") || 
      (language === "vietnamese") || 
      (language === "VT") || 
      (language === "vt") ){
        return "vt"
      }
      } else{
        log(language , 'no input language received');
        return ""
      }
    }

    submitAnswer = (question) =>{
      return new Promise((resolve , reject)=>{
        return axios
              .get(
                `/backend/submit?answer=${
                  question.answer
                }&sys_id=${question.sys_id}&participant_id=${this.state.patientSysId}`
              )
              .then((suc) => {
                log(suc, "submitted answers");
                return
              })
              .catch((e) => {
                log(e , 'err submitting prescreening responses')
                alert("Error submitting responses")
              });
      })
    }

    onRecaptchaChange = (value) =>{
      log(value,'value recaptcha')
      this.setState({ recaptcha_response : value})
    }

    submitAnswers = () =>{
      let questions = window.localStorage.getItem("prescreening");
      log(JSON.parse(questions) , 'after fetching')
      let parsedQuestions = JSON.parse(questions)
      if(parsedQuestions !== null){
        if(parsedQuestions && parsedQuestions.length > 0){
          parsedQuestions.map((ques) => {
            //log(this.state[ques.sys_id], "for submitting");
            this.submitAnswer(ques).then(submit=>{
              log(submit , 'submitted answer')
            }).catch(errSubmit=>{
              log(errSubmit , 'err while submitting answers')
            })
          });
          // setTimeout(()=>{
          //   this.shareRecords()
          // },1500)
        }else{
          log(questions , 'no questions found');
          return;
        }
      }else{
        log(questions , 'err fetching prescreening data');
        alert("Error fetching prescreening data")
      }
    }

    signUp = () => {
      const {
        city,
        firstname,
        lastname,
        email,
        gender,
        phone,
        dob,
        race,
        smokingstatus,
        primarylanguage,
        study_sys_id,
        country,
        zipcode,
        stateterritory,
        timezone,
        patient_id,
        nctid,
        currentmedication,
        healthcondition,
        familyhistory,
        domain,
        criteria,
        currentpage,
        showModal,
        guest_id,
        studyId,
        recaptcha_response
      } = this.state;
      let checkEmptyValues = [
        firstname,
        lastname,
        city,
        country,
        zipcode,
        email,
        gender,
        phone,
        dob,
        smokingstatus,
        primarylanguage,
        race,
        healthcondition,
        recaptcha_response
      ];
      let check = checkEmptyValues.filter(
        (item) => item === "" || null || undefined
      );
      if (check && check.length > 0) {
        //log(`${check}, "check"`);
        alert("please enter all required values");
      } else {
        //log("do further");
        this.setState({ showAcknowledgement: true });
        let request_body = {
          recaptcha_response : recaptcha_response,
          preferred_name: firstname,
          last_name: lastname,
          gender: gender,
          date_of_birth: dob,
          email: email,
          phone_number: phone,
          race: race.toString(),
          study: study_sys_id,
          language: primarylanguage,
          country: country,
          zip_code_or_city: zipcode,
          state_territory: stateterritory,
          time_zone: timezone,
          domain: domain,
          patient_id: patient_id,
          nct_number : nctid,
          current_medications: currentmedication,
          health_condition: healthcondition,
          relevant_family_history: familyhistory,
          smoking_status: smokingstatus,
          guest_id: guest_id,
          other_information:
            criteria === null ? currentpage : criteria + currentpage,
          sys_domain : domain,
          study_number : studyId
        };
        //log(`${request_body}, "request body"`);
        log(request_body, 'body');
        axios
          .post("/backend/participant", request_body)
          .then((success) => {
            //log(`${success.status},'then'`);
            if (
              (success && success.status === 201) ||
              (success && success.status === 200)
            ) {
              //log(`${success && success.data.result}, "created participant"`);
              this.setState(
                {
                  patient_id:
                    success &&
                    success.data &&
                    success.data.pt_id,
                },
                () => {
                  this.setState({ showModal: !showModal });
                  let patientSysId =
                    success &&
                    success.data &&
                    success.data.pt_sys_id;
                  let uName = success &&
                  success.data &&
                  success.data.u_id;
                  this.setState({ patientSysId: patientSysId, pt_uname: uName  }, () => {
                    this.setState({ showModal: false, loadingForModal: true },()=>{
                      this.submitAnswers()
                    });
                    //this.setState({ isParticipantEnrolled: true });
                  });
                }
              );
            } else if (
              success &&
              success.data &&
              success.data.failure === "failure"
            ) {
              let participantError =
                success &&
                success.data &&
                success.data.error &&
                success.data.error.message;
              alert(participantError + "Error while creating participant");
              this.setState({
                loadingForParticipantModal: false,
                showAcknowledgement: false,
              });
            } else {
              //log(`${success.status},'then'`);
              alert("Error while creating participant");
              this.setState({
                loadingForParticipantModal: false,
                showAcknowledgement: false,
              });
            }
          })
          .catch((errSuccess) => {
            // log(
            //   `${errSuccess},${errSuccess.status}, "error creating participant"`
            // );
            //log(errSuccess,errSuccess.response ,'err while creating participant');
            if(errSuccess && errSuccess.response && errSuccess.response.status === 400){
              alert(
                "Error while creating participant"
              );
              this.setState({ loadingForParticipantModal: false, showAcknowledgement: false });
            }else if(errSuccess && errSuccess.response && errSuccess.response.status === 403){
              alert(
                "Error while creating participant looks like email already exists"
              );
              this.setState({ loadingForParticipantModal: false, showAcknowledgement: false });
            }
          });
      }
    };

    mapProfie =() =>{
      let records = this.state.records;
      if(records && records.length > 0){
        let details = records[4];
        log(details && details.profileDetails && details.profileDetails[0] , 'profile details')
        let profile = details && details.profileDetails && details.profileDetails[0];
        if(profile !== undefined){
          this.setState({
            country: this.setCountry(profile && profile.country && profile.country !==undefined ? profile.country : "") ,
            city: profile.city  ,
            zipcode: profile.zipcode ,
            firstname: profile.firstName[0],
            lastname: profile.lastName[0],
            email: profile.email,
            gender: profile.gender,
            dob: profile.dob,
            phone: profile.mobile,
            primarylanguage: this.setLanguage(profile && profile.language && profile.language !==undefined ? profile.language : "")
          })
        }else{
          log(profile , 'cannot get profile details')
        }
      }else{
        log(records , 'no records found')
      }
    }

    componentDidMount(){
        let params = this.props.match.params;
        let nctid = window.localStorage.getItem("nctId");
      let  { randomID , study_sys_id , domain_id, study_number } = params
      log(randomID , 'params')
      if(randomID && randomID.length > 0){
        this.setState({ 
            user_id : randomID,
            study_sys_id : study_sys_id,
            domain : domain_id,
            studyId : study_number,
            nctid : nctid
           },()=>{
            fetchRandomRecords(this.state.user_id)
                .then((records) => {
                  log(records, "records");
                  this.setState({ records: records }, () => {
                    this.setState({ isLoading: true },()=>{
                      this.mapProfie()
                    });
                  });
                })
                .catch((errRecords) => {
                  log(errRecords, "err with records");
                  this.setState({ records: [] }, () => {
                    this.setState({ isLoading: true });
                  });
                });
           })
      }else{
        log(randomID , 'err getting random number');
        alert("Error occured")
      }
    }

    changeOption =(status)=>{
      this.setState({ showParticipantModal : status , loadingForParticipantModal : true })
    }

    render(){
        let { isLoading , records , showParticipantModal, loadingForModal , loadingForParticipantModal } = this.state
        return(
            <div className="my-5 py-5 container">
                <h6>Electronic Medical Records</h6>
                {isLoading === true ? 
                <div>
                    <div className="row my-3">
                <div className="col-lg-6 px-0">
                  <h6>
                    Immunizations :{" "}
                    {records[0]["immunizations"] &&
                      records[0]["immunizations"].length}
                  </h6>
                  <div
                    style={{ height: 350, overflowY: "scroll" }}
                    className="my-2"
                  >
                    {records[0]["immunizations"] &&
                    records[0]["immunizations"].length > 0
                      ? records[0]["immunizations"].map((immunization) => (
                          <div
                            style={{ backgroundColor: "#fbf8f3" }}
                            key={immunization.id}
                            className="border p-2 mr-3 my-1"
                          >
                            <h6>{immunization.name || "None"}</h6>
                            <p className="mb-0">
                              {immunization.date || "None"}
                            </p>
                            <p className="mb-0">
                              {immunization.manufacturer || "None"}
                            </p>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div className="col-lg-6 px-0">
                  <h6>
                    Allergies :{" "}
                    {records[2]["allergies"] && records[2]["allergies"].length}
                  </h6>
                  <div
                    style={{ height: 350, overflowY: "scroll" }}
                    className="my-2"
                  >
                    {records[2]["allergies"] &&
                    records[2]["allergies"].length > 0
                      ? records[2]["allergies"].map((allergy) => (
                          <div
                            style={{ backgroundColor: "#ebf9fc" }}
                            key={allergy.id}
                            className="border p-2 mr-3 my-1"
                          >
                            <h6>{allergy.name || "None"}</h6>
                            <p className="mb-0">{allergy.date || "None"}</p>
                            <p className="mb-0">{allergy.category || "None"}</p>
                            <p className="mb-0">{allergy.severity || "None"}</p>
                            <p className="mb-0">{allergy.status || "None"}</p>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-lg-6 px-0">
                  <h6>
                    Medications :{" "}
                    {records[1]["medications"] &&
                      records[1]["medications"].length}
                  </h6>
                  <div
                    style={{ height: 350, overflowY: "scroll" }}
                    className="my-2"
                  >
                    {records[1]["medications"] &&
                    records[1]["medications"].length > 0
                      ? records[1]["medications"].map((medication) => (
                          <div
                            style={{ backgroundColor: "#fbf5f6" }}
                            key={medication.id}
                            className="border p-2 mr-3 my-1"
                          >
                            <h6>{medication.name || "None"}</h6>
                            <p className="mb-0">{medication.date || "None"}</p>
                            <p className="mb-0">
                              {medication.dosage || "None"}
                            </p>
                            <p className="mb-0">{medication.taken || "None"}</p>
                            <p className="mb-0">
                              {medication.status || "None"}
                            </p>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div className="col-lg-6 px-0">
                  <h6>
                    Conditions :{" "}
                    {records[3]["conditions"] &&
                      records[3]["conditions"].length}
                  </h6>
                  <div
                    style={{ height: 350, overflowY: "scroll" }}
                    className="my-2"
                  >
                    {records[3]["conditions"] &&
                    records[3]["conditions"].length > 0
                      ? records[3]["conditions"].map((condition) => (
                          <div
                            style={{ backgroundColor: "#f3f8f5" }}
                            key={condition.id}
                            className="border p-2 my-1 mr-3"
                          >
                            <h6>{condition.name || "None"}</h6>
                            <p className="mb-0">{condition.date || "None"}</p>
                            <p className="mb-0">
                              {condition.verificationstatus || "None"}
                            </p>
                            <p className="mb-0">
                              {condition.clinicalstatus || "None"}
                            </p>
                            <p className="mb-0">
                              {condition.onsetdatetime || "None"}
                            </p>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>
              <ParticipantModal 
                signUp = {this.signUp}
                showParticipantModal = {showParticipantModal}
                changeOption = {this.changeOption}
                loadingForModal = {loadingForModal}
                loadingForParticipantModal={loadingForParticipantModal}
                onAcknowledge = {this.onAcknowledge}
                onRecaptchaChange={this.onRecaptchaChange}
                showAcknowledgement = {this.state.showAcknowledgement}
                onTextInputChange={this.onTextInputChange}
                onRaceCheckboxChange = {this.onRaceCheckboxChange}
                fields = {this.state}
              />
                </div> : <Loading />}
            </div>
        )
    }
}

export default Records