import {withRouter} from 'react-router-dom';
import React from "react";
import "../App.css";
import "../bootstrap.css";
import {
  Cancer,
  Cardiology,
  ChronicCondition,
  Therapeutic,
  arrowRight,
  Covid,
  iLook,
  iCovid,
  iCmv,
  iCancer,
  iEczema,
} from "../features/theme/icons";
const options = [
  { id: 1, color: "#8CD2A9", title: "Respiratory Syncytial Virus", icon: iLook ,link:"/study/NCT05127434"},
  { id: 2,color: "#F6BFBF", title: "COVID-19", icon: iCovid ,link:"/study/NCT04860297"},
  { id: 3,color: "#C9AFD2", title: "Cytomegalovirus Virus CMV", icon: iCmv ,link:" /study/NCT05085366"},
  { id: 4,color: "#76D6EE", title: "Cancer", icon: iCancer ,link:"/results?q=Cancer&in=UNITED%20STATES"},
  // { color: "#8CD2A9", title: "Cytomegalovirus", icon: Cardiology,link:"/study/NCT04232280" },
  { id: 5,color: "#F2D888", title: "Eczema", icon: iEczema,link:"/study/NCT04773600" },
];

const Option = ({ id, title, icon , link , next}) => {
  return (
    <div style={{cursor : 'pointer'}} onClick={()=>next(link)} 
      //className={`${(id === 1) || id === 3 ? "col-lg-3" : "col-lg-2"} ${(id === 1 && "pl-0") || (id === 5 && "pr-0")} my-1`}
      >
        <div className="option d-flex align-items-center justify-content-start p-1 mr-4">
           <img className='p-2' src={icon} alt={"condition"}></img>
           <p className='mb-0 p-1 ml-1'>{title}</p>
        </div>
    </div>
  );
};


const PopularSearch = (props) => {

  const next =(link)=>{
    window.location.href = link
  }

  return (
    <div className="">
      <div className='psearch_options d-flex align-items-center justify-content-start'>
        {options.map((item) => (
            <Option key={item.id} id={item.id} title={item.title} icon={item.icon} link={item.link} next = {next} />
        ))}
      </div>
    </div>
  );
};

export default withRouter(PopularSearch);
