import React from "react";
import axios from "axios";
import { Debug, Domain, Google_Map_Key } from "../constants";
// to disable all enroll buttons
const disableEnrollButton = true;
const iconUrl = "https://patientrials.com/images/PT_Rounded.png";
//Object.entries
if (!Object.entries) {
  Object.entries = function (obj) {
    let ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i); // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
  };
}

const log = (...message) => {
  if (Debug === false) return;
  return console.log(...message);
};

// const log = (message) => {
//   if (Debug === false) return;
//   return console.log(message);
// };

const melanomaFaqs = [
  {
    id: 1,
    question: "What does mRNA do?",
    answer: `mRNA produces instructions to make proteins that may treat or prevent disease. mRNA medicines aren’t
    small molecules, like traditional pharmaceuticals. And they aren’t traditional biologics (recombinant
    proteins and monoclonal antibodies) – which were the genesis of the biotech industry. Instead, mRNA
    medicines are sets of instructions. And these instructions direct cells in the body to make proteins to
    prevent or fight disease.
    It’s actually basic human biology.
    DNA (deoxyribonucleic acid) is a double-stranded molecule that stores the genetic instructions your
    body’s cells need to make proteins. Proteins, on the other hand, are the ‘workhorses’ of the body. Nearly
    every function in the human body – both normal and disease-related – is carried out by one or many
    proteins.
    mRNA is just as critical as DNA.
    Without mRNA, your genetic code would never get used by your body. Proteins would never get made.
    And your body wouldn’t – actually couldn’t – perform its functions. Messenger ribonucleuc acid, or
    mRNA for short, plays a vital role in human biology, specifically in a process known as protein synthesis.
    mRNA is a single-stranded molecule that carries genetic code from DNA in a cell’s nucleus to ribosomes,
    the cell’s protein-making machinery.`,
  },
  {
    id: 2,
    question: "How can I check to see if I'm eligible for this trial?",
    answer: `You can check if you are potentially eligible for this trial by completing a set of screening questions. You
    can find these on this web page by finding the ‘Get Started’ button`,
  },
  {
    id: 3,
    question: "What does a typical research study look like?",
    answer: `Clinical trials help determine the safety and effectiveness of new treatments. A research plan or protocol is
    created that outlines how trial participants may receive interventions which can include investigational
    medications, devices, or procedures. The trial may compare a new medical approach to a standard
    treatment option already available, to a placebo with no active investigational medication, or to no
    intervention at all. The trial team will share more details before you participate. For more information
    about this trial, please see the ‘What to Expect’ section of this website.`,
  },
  {
    id: 4,
    question: "Can I stop the trial at any time?",
    answer: `You can leave the study at any time by telling your study doctor that you no longer want to take part. Your
    choice will not change the quality of care you receive outside of this study.`,
  },
  {
    id: 5,
    question: "What personal information is collected about you for the study?",
    answer: `The study doctor and other study staff will collect your personal information. This may include:
    -*- Your name, address, telephone number, health insurance number where applicable.
    -*- Your age, gender, ethnic and racial background.
    -*- Lifestyle information; health and medical history.
    -*- Your study treatments and response to study treatments.
    -*- Data resulting from testing your biological samples and any images taken`,
  },
  {
    id: 6,
    question: "Who has access to your personal information?",
    answer: `All your personal information collected for this study will be stored in the study medical records at the
    study site. Sponsor staff, review boards and ethics committees (that approve and monitor studies), and
    others may check the study records. This is done to make sure that the study is being run properly.
    Regulatory agencies, such as the US Food and Drug Administration (FDA), European Medicine Agency
    (EMA) or others, review and approve new medicines. These agencies will be granted direct access to your
    information. This is so they can verify clinical trial procedures and/or data. If you share your personal data
    to contact a site, that data will be used by TrialScope to contact you about clinical trials and related
    information.`,
  },
];
const covidFaqs = [
  {
    id: 1,
    question: "Can I get COVID-19 from the study vaccine being researched?",
    answer: `You cannot get infected with SARS-CoV-2 or get COVID-19 illness from the study vaccine.`,
  },
  {
    id: 2,
    question: "Why is diversity important in this clinical trial?",
    answer: `Having a diverse patient population in clinical trials is important since medicines sometimes affect people differently based on age, gender and race.
    Given that certain populations can respond differently to medical therapies, it is extremely important that this vaccine clinical trial include people from all races
    and ethnicities to ensure the vaccine is safe and effective for everyone to receive.`,
  },
  {
    id: 3,
    question: "Will I get paid to be a part of this clinical trial?",
    answer: `Yes, you will be paid for your time. We realize it takes a lot of time to come for study visits, so we want to make sure this is not too much of a burden for you.
    The study site will review all these details with you. Also, you do not have to pay anything to be in the study. All study related services are provided at no cost.`,
  },
  {
    id: 4,
    question: "Does the study vaccine contain a live virus?",
    answer: `The vaccine does not contain a live virus. It contains a purified protein that can neither replicate nor can it cause COVID-19`,
  },
  {
    id: 5,
    question: "What is a placebo?",
    answer: `In order to understand if the study vaccine works, medical researchers are comparing the study vaccine to a saltwater placebo. A placebo looks like the study
    12/15/20, 9:20 PM
    Page 6 of 7
    vaccine being tested but does not contain the vaccine.
    All study volunteers will receive the same level of quality care regardless of if they are assigned to the study vaccine or the placebo`,
  },
  {
    id: 6,
    question:
      "What happens if I am diagnosed with COVID-19 while in this trial?",
    answer: `Each study volunteer will be closely monitored by the study doctor and medical staff for symptoms of COVID-19 while in the clinical trial. If study
    participants are diagnosed with COVID-19 at any time during this research study, they should contact the study staff immediately for further instructions.`,
  },
  {
    id: 7,
    question: "How can I check to see if I’m eligible for this trial?",
    answer: `You can check if you are potentially eligible for this trial by completing a set of screening questions. You can find these on this web page by clicking the ‘Get
    Started’ button.`,
  },
  {
    id: 8,
    question: "What does a typical clinical trial look like?",
    answer: `Clinical trials determine the safety and effectiveness of new vaccines or treatments that are not yet approved by the U.S. Food and Drug Administration. A
    research plan or protocol is created that outlines how trial participants may receive interventions, which can include investigational medications, devices, or
    procedures. The trial may compare a new medical approach to a standard treatment option already available, to a placebo with no active investigational
    medication, or to no intervention at all. The study team will share more details before you participate.`,
  },
  {
    id: 9,
    question: "Can I quit the trial at any time?",
    answer: `Study volunteers have the right to drop out of the study at any time for any reason, or no reason at all. The study doctor or sponsor also has the right to
    discontinue a study volunteer at any time for the participant’s safety, or if the participant no longer meets the study requirements.`,
  },
  {
    id: 10,
    question: "What personal information is collected about you for the study?",
    answer: `Your personal health information includes information obtained from your medical records, history, physical examinations or other procedures to determine
    your eligibility for the study and information that is created or collected from you during your participation in the study, including the results of various tests
    and procedures. The study personnel may see information that may identify you, such as: your address, telephone number, Social Security number, health plan
    number, year of birth, age, gender, race, ethnicity, or dates relating to various tests and procedures, or other identifying information. This information will be
    removed and replaced with your study number before your information leaves the study site. Your personal health information will be recorded in your medical
    records.`,
  },
  {
    id: 11,
    question: "Who has access to your personal information?",
    answer: `Your medical records will remain at the study site and will be kept confidential, except as described in this authorization. It may be necessary for the study
    doctor to disclose the records identifying you (including your original medical records) to authorized personnel of the sponsor, its agents, collaborators,
    research partners, assignees or designees (the “sponsor”), the Institutional Review Board of this study (the “IRB”), auditors, the U.S. Food and Drug
    Administration or other regulatory authorities (“Regulatory Authorities”), or other persons or entities as required by law. This disclosure, which will take place
    at the study site, may be required to verify that the study is conducted appropriately and that the information collected for the study is correct and complete. If
    you share your personal data to contact a site, that data will be used by TrialScope to contact you about clinical trials and related information.`,
  },
];
const schizophreniaFaqs = [
  {
    id: 1,
    question: "Will I get paid to be a part of this clinical trial?",
    answer: `Yes, you will be reimbursed for your time. We realize it takes a lot of time to come for study visits, so we want to make sure this is not too much of a burden for you. The study site will review all these details with you. Also, you do not have to pay anything to be in the study. All study related services are provided at no cost.`,
  },
  {
    id: 2,
    question: "What is a placebo?",
    answer: `In order to understand if the investigational drug works, medical researchers are comparing the investigational drug to a placebo. A placebo looks like the investigational drug being tested but does not contain the drug.

    All study volunteers will receive the same level of quality care regardless of if they are assigned to the investigational drug or the placebo.`,
  },
  {
    id: 3,
    question: "How can I check to see if I’m eligible for this trial?",
    answer: `You can check if you are potentially eligible for this trial by completing a set of screening questions. You can find these on this web page by clicking the ‘Get Started’ button.`,
  },
  {
    id: 4,
    question: "What does a typical research study look like?",
    answer: `Clinical trials help determine the safety and effectiveness of new vaccines or treatments. A research plan or protocol is created that outlines how trial participants may receive interventions which can include investigational medications, devices, or procedures. The trial may compare a new medical approach to a standard treatment option already available, to a placebo with no active investigational medication, or to no intervention at all. The trial team will share more details before you participate.`,
  },
  {
    id: 5,
    question: "Can I quit the trial at any time?",
    answer: `Study volunteers have the right to drop out of the study at any time for any reason, or no reason at all. The study doctor or sponsor also has the right to discontinue a study volunteer at any time for the participant’s safety, or if the participant no longer meets the study requirements.`,
  },
  {
    id: 6,
    question: "What personal information is collected about you for the study?",
    answer: `The study doctor and other study staff will collect your personal information. This may include:

    -*- Your name, address, telephone number, health insurance number where applicable.
    
    -*- Your age, sex, ethnic and racial background.
    
    -*- Lifestyle information; health and medical history.
    
    -*- Your study treatments and response to study treatments.
  
    -*- Data resulting from testing your biological samples.`,
  },
  {
    id: 7,
    question: "Who has access to your personal information?",
    answer: `All your personal information collected for this study will be securely stored in the study medical records at the study site. Sponsor staff, review boards and ethics committees (that approve and monitor studies), and others who have a need to know such as our data hosting provider, and government regulators may check the study records. This is done to make sure that the study is being run properly. Regulatory agencies, such as the US Food and Drug Administration (FDA), European Medicine Agency (EMA) or others, review and approve new medicines. These agencies will be granted direct access to your information. This is so they can verify clinical trial procedures and/or data. If you share your personal data to contact a site, that data will be used by TrialScope to contact you about clinical trials and related information.`,
  },
  {
    id: 8,
    question: "Notices and other information",
    answer: `AVANIR and Avanir logos are trademarks or registered trademarks of Avanir Pharmaceuticals, Inc. in the United States or other countries.`,
  },
];
const colorectalCancerFaqs = [
  {
    id: 1,
    question: "Will I get paid to be a part of this study?",
    answer: `You won’t be paid for taking part in this study. You may be able to have some of your travel costs paid back. The study site can answer any questions you have.

    The sponsor of the study, Seagen, will pay for the study drugs and any procedures you have that are a part of this study. Your insurance will be billed for your regular medical care. It’s possible your insurance company may not cover all of your medical costs. All services that are part of this study will be free to you.`,
  },
  {
    id: 2,
    question: "How can I check to see if I qualify for this trial?",
    answer: `You can complete a set of pre-screening questions to see if you might qualify to take part in this study. You can find these on this web page by clicking the ‘Get Started’ button. You can also discuss this with your doctor if you are unsure about any of the qualifying criteria for the study.`,
  },
  {
    id: 3,
    question: "Can I quit the study at any time?",
    answer: `You can change your mind about taking part in this study at any time for any reason. Your study doctor or the sponsor, Seagen, may stop you from taking part in this study if they think it’s in your best interest.`,
  },
  {
    id: 4,
    question: "What personal information is collected about you for the study?",
    answer: `Your study doctor and other study staff will collect your personal information. As part of this study, we will review your medical records. We will collect and share the health information in your medical records. This includes your name, race, gender, city, zip code, and medical history. It may also include your study schedule, the results of study tests and procedures, and interviews.`,
  },
  {
    id: 5,
    question: "Who has access to your personal information?",
    answer: `All your personal information collected for this study will be securely stored in the study medical records at the study site.

    We may use and share your information as follows:
    
    -*- for treatment and healthcare purposes
    -*- to run the study
    -*- to make sure that this study meets legal and safety requirements, including the requirements of the hospitals and clinics running the study, and the requirements of special boards allowing the hospitals and clinics to run the studies
    -*- to collect payment for your treatment
    -*- We may also share your information with other companies who help develop the study drug or file applications with regulatory agencies (like the US Food and Drug Administration, the FDA) to get approval for the study drug.
    
    If you share your personal data to contact a site, that data will be used by TrialScope to contact you about clinical trials and related information.`,
  },
];
const cmvFaqs = [
  {
    id: 1,
    question: "What is cytomegalovirus (CMV)?",
    answer: `CMV is a common virus throughout the world. It will infect most people at some time in their life. In
    healthy children and adults, the virus usually causes no symptoms and poses no threat to their health.
    CMV is a concern because it can lead to health problems when unborn babies or people with weakened
    immune systems are infected. CMV is spread from one person to another when there is contact with fluids
    from the body, such as saliva. CMV is not transmitted in the air. CMV can also be transmitted from a
    pregnant woman to her unborn child. CMV is the most common viral infection passed from mother to
    unborn child and leading cause of birth defects due to infection in the United States. Some babies born
    with CMV infection develop problems such as hearing loss, learning problems, or vision abnormalities
    and can even die in the most severe cases.

    The best option to prevent CMV is the use of a safe and effective vaccine to protect women from CMV
    infection and potentially passing CMV infection to their unborn child if exposed during pregnancy.`,
  },
  {
    id: 2,
    question: "How can I check to see if I’m eligible for this trial?",
    answer: `You can check if you are potentially eligible for this trial by completing a set of screening questions. You
    can find these on this web page by clicking the ‘Get Started’ button.`,
  },
  {
    id: 3,
    question: "Will I get paid to participate in this study?",
    answer: `Yes, you will be compensated for your time and for each study visit you complete.`,
  },
  {
    id: 4,
    question: "What does a typical clinical trial look like?",
    answer: `Clinical trials are scientific studies that help to determine if and how new treatments work. A research
    plan or protocol is created that outlines how trial participants may receive the treatment being tested. The
    trial may compare the experimental treatment to a standard treatment for the illness, to a placebo with no
    active medication, or to no intervention at all. The trial team will share more details before you participate.
    For more information about this trial, please see the ‘What to Expect’ section of this website.`,
  },
  {
    id: 5,
    question: "What does the phase of a study mean?",
    answer: `In general, a study's "phase" identifies it's purpose.
    -*- A Phase 1 study tests whether or not a vaccine is safe, and if the body's immune system responds to
    the vaccine in a small number (20-100) of healthy volunteers.
    -*- A Phase 2 study tests for safety and for the body's immune response to find the best dose of the
    vaccine in hundreds of healthy volunteers. This study is a Phase 2 study.
    -*- A Phase 3 study tests for safety, and also if the vaccine actually prevents infection in a large number
    of volunteers (>1000).
    -*- A vaccine may be approved by the Food and Drug Administration (FDA) only if the goals are met in all of
    the study phases.`,
  },
  {
    id: 6,
    question: "Can I stop the trial at any time?",
    answer: `You can leave the study at any time by telling your study doctor that you no longer want to take part. Your
    choice will not change the quality of care you receive outside of this study.`,
  },
  {
    id: 7,
    question: "Does the mRNA-1647 study vaccine contain a live virus?",
    answer: `Normally, vaccines for viruses are made from a weakened or inactive virus. However, the mRNA study
    vaccine is different. It is made from molecules called mRNA that tell cells how to make specific proteins.
    In this case, the proteins are two small parts of the virus that do not cause CMV illness but help the body's
    immune system recognize and protect itself if it comes in contact with infectious CMV virus. Basically,
    you make your own vaccine.
    You cannot become infected with CMV from receiving the study vaccine.`,
  },
  {
    id: 8,
    question: "What personal information is collected about you for the study?",
    answer: `The study doctor and other study staff will collect your personal information.
    -*- Your name, address, telephone number, health insurance number where applicable.
    -*- Your age, gender, ethnic and racial background.
    -*- Lifestyle information; health and medical history.
    -*- Your study treatments and response to study treatments.
    -*- Data resulting from testing your biological samples and any images taken.`,
  },
  {
    id: 9,
    question: "Who has access to your personal information?",
    answer: `All your personal information collected for this study will be stored in the study medical records at the
    study site. Sponsor staff, review boards and ethics committees (that approve and monitor studies), and
    others may check the study records. This is done to make sure that the study is being run properly.
    Regulatory agencies, such as the US Food and Drug Administration (FDA), European Medicine Agency
    (EMA) or others, review and approve new medicines. These agencies will be granted direct access to your
    information. This is so they can verify clinical trial procedures and/or data. If you share your personal data
    to contact a site, that data will be used by TrialScope to contact you about clinical trials and related
    information.`,
  },
];

let locationDetails = {};
// const countries = [
//   "Argentina",
//   "Australia",
//   "Brazil",
//   "Canada",
//   "Ecuador",
//   "Chile",
//   "China",
//   "Egypt",
//   "Hong Kong",
//   "India",
//   "Indonesia",
//   "Japan",
//   "Kenya",
//   "Malaysia",
//   "Mexico",
//   "New Zealand",
//   "Nigeria",
//   "Pakistan",
//   "Peru",
//   "Philippines",
//   "Republic of Korea",
//   "Russia",
//   "Singapore",
//   "South Africa",
//   "Sri Lanka",
//   "Taiwan",
//   "Thailand",
//   "Turkey",
//   "Vietnam",
//   "Ukraine",
//   "UAE",
//   "USA",
//   "Zimbabwe",
// ];

const countries = [
  "AFGHANISTAN",
  "ALBANIA",
  "ALGERIA",
  "AMERICAN SAMOA",
  "ANDORRA",
  "ANGOLA",
  "ANGUILLA",
  "ANTARCTICA",
  "ANTIGUA AND BARBUDA",
  "ARGENTINA",
  "ARMENIA",
  "ARUBA",
  "AUSTRALIA",
  "AZERBAIJAN",
  "BAHAMAS",
  "BAHRAIN",
  "BANGLADESH",
  "BARBADOS",
  "BELARUS",
  "BELIZE",
  "BENIN",
  "BERMUDA",
  "BHUTAN",
  "BOLIVIA",
  "BOSNIA AND HERZEGOVINA",
  "BOTSWANA",
  "BOUVET ISLAND",
  "BRAZIL",
  "BRITISH INDIAN OCEAN TERRITORY",
  "BRUNEI DARUSSALAM",
  "BURKINA FASO",
  "BURUNDI",
  "CAMBODIA",
  "CAMEROON",
  "CANADA",
  "CAPE VERDE",
  "CAYMAN ISLANDS",
  "CENTRAL AFRICAN REPUBLIC",
  "CEUTA",
  "CHAD",
  "CHILE",
  "CHINA",
  "CHRISTMAS ISLAND",
  "COCOS (KEELING) ISLANDS",
  "COLOMBIA",
  "COMOROS",
  "CONGO",
  "COOK ISLANDS",
  "COSTA RICA",
  "COTE D'IVOIRE",
  "CUBA",
  "DEMOCRATIC REPUBLIC OF CONGO",
  "DJIBOUTI",
  "DOMINICA",
  "DOMINICAN REPUBLIC",
  "ECUADOR",
  "EGYPTIRAN",
  "EL SALVADOR",
  "EQUATORIAL GUINEA",
  "ERITREA",
  "ETHIOPIA",
  "FALKLAND ISLANDS",
  "FAROE ISLANDS",
  "FIJI",
  "FRENCH POLYNESIA",
  "FRENCH SOUTHERN AND ANTARCTIC LANDS",
  "GABON",
  "GAMBIA",
  "GEORGIA",
  "GHANA",
  "GIBRALTAR",
  "GREENLAND",
  "GRENADA",
  "GUAM",
  "GUATEMALA",
  "GUINEA",
  "GUINEA-BISSAU",
  "GUYANA",
  "HAITI",
  "HEARD AND MC DONALD ISLANDS",
  "HONDURAS",
  "HONG KONG",
  "ICELAND",
  "INDIA",
  "INDONESIA",
  "IRAN",
  "IRAQ",
  "IRELAND",
  "ISRAEL",
  "JAMAICA",
  "JAPAN",
  "JORDAN",
  "KAZAKHSTAN",
  "KENYA",
  "KIRIBATI",
  "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF (NORTH)",
  "KOREA, REPUBLIC OF (SOUTH)",
  "KOSOVO",
  "KUWAIT",
  "KYRGYZSTAN",
  "LAOS",
  "LEBANON",
  "LESOTHO",
  "LIBERIA",
  "LIBYA",
  "LIECHTENSTEIN",
  "MACAU",
  "REPUBLIC OF NORTH MACEDONIA",
  "MADAGASCAR",
  "MALAWI",
  "MALAYSIA",
  "MALDIVES",
  "MALI",
  "MARSHALL ISLANDS (THE REPUBLIC OF)",
  "MAURITANIA",
  "MAURITIUS",
  "MELILLA",
  "MEXICO",
  "MICRONESIA (FEDERATED STATES OF)",
  "MOLDOVA (THE REPUBLIC OF)",
  "MONGOLIA",
  "MONTENEGRO",
  "MONTSERRAT",
  "MOROCCO",
  "MOZAMBIQUE",
  "MYANMAR",
  "NAMIBIA",
  "NAURU",
  "NEPAL",
  "NETHERLANDS ANTILLES",
  "NEW CALEDONIA",
  "NEW ZEALAND",
  "NICARAGUA",
  "NIGER",
  "NIGERIA",
  "NIUE",
  "NORFOLK ISLAND",
  "NORTHERN MARIANA ISLANDS",
  "NORWAY",
  "PALESTINE",
  "OMAN",
  "PAKISTAN",
  "PALAU",
  "PANAMA",
  "PAPUA NEW GUINEA",
  "PARAGUAY",
  "PERU",
  "PHILIPPINES",
  "PITCAIRN ISLANDS",
  "QATAR",
  "RUSSIAN FEDERATION",
  "RWANDA",
  "SAINT BARTHELEMY",
  "SAINT HELENA (INCL ASCENSION ISLAND AND TRISTAN DE CUNHA)",
  "SAINT KITTS AND NEVIS",
  "SAINT LUCIA",
  "SAINT PIERRE AND MIQUELON",
  "SAINT VINCENT AND THE GRENADINES",
  "SAMOA",
  "SAN MARINO",
  "SAO TOME AND PRINCIPE",
  "SAUDI ARABIA",
  "SENEGAL",
  "SERBIA",
  "SEYCHELLES",
  "SIERRA LEONE",
  "SINGAPORE",
  "SOLOMON ISLANDS",
  "SOMALIA",
  "SOUTH AFRICA",
  "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
  "SOUTH SUDAN",
  "SRI LANKA",
  "SUDAN",
  "SURINAME",
  "SWAZILAND",
  "SYRIA",
  "TAIWAN",
  "TAJIKISTAN",
  "TANZANIA",
  "THAILAND",
  "TIMOR LESTE",
  "TOGO",
  "TOKELAU",
  "TONGA",
  "TRINIDAD AND TOBAGO",
  "TUNISIA",
  "TURKEY",
  "TURKMENISTAN",
  "TURKS AND CAICOS ISLANDS",
  "TUVALU",
  "UGANDA",
  "UKRAINE",
  "UNITED ARAB EMIRATES",
  "UNITED STATES",
  "UNITED STATES MINOR OUTLYING ISLANDS",
  "URUGUAY",
  "UZBEKISTAN",
  "VANUATU",
  "VATICAN CITY STATE (HOLY SEE)",
  "VENEZUELA",
  "VIETNAM",
  "VIRGIN ISLANDS (BRITISH)",
  "VIRGIN ISLANDS (U.S.)",
  "WALLIS AND FUTUNA ISLANDS",
  "YEMEN",
  "ZAMBIA",
  "ZIMBABWE",
];
const getLocationDetails = (
  type,
  city,
  state,
  address,
  zipcode,
  country,
  name
) => {
  if (type === "ts") {
    let arr = [
      { term: "city", value: city },
      { term: "state", value: state },
      { term: "country", value: country },
      { term: "zipcode", value: zipcode },
    ];
    arr.map((item) => (locationDetails[item.term] = item.value));
    return locationDetails;
  } else if (type === "ps") {
    let arr = [
      { term: "city", value: city },
      { term: "state", value: state },
      { term: "country", value: country },
      { term: "zipcode", value: zipcode },
      { term: "address", value: address },
      { term: "sitename", value: name },
    ];
    arr.map((item) => (locationDetails[item.term] = item.value));
    return locationDetails;
  }
};

const location = () => {
  return locationDetails;
};

const changeSidebarFilters = (type, obj) => {
  if (type === "cs") {
  } else if (type === "ts") {
    let reqarr = Object.keys(obj);
    reqarr.splice(
      reqarr.indexOf(reqarr.filter((a) => a === "overallStatus")[0]),
      1
    );
    reqarr.splice(
      reqarr.indexOf(reqarr.filter((a) => a === "maximumAge")[0]),
      1
    );
    reqarr.splice(
      reqarr.indexOf(reqarr.filter((a) => a === "minimumAge")[0]),
      1
    );
    reqarr.splice(reqarr.indexOf(reqarr.filter((a) => a === "gender")[0]), 1);
    reqarr.splice(reqarr.indexOf(reqarr.filter((a) => a === "phase")[0]), 1);
    reqarr.splice(
      reqarr.indexOf(reqarr.filter((a) => a === "conditions")[0]),
      1
    );
    reqarr.splice(
      reqarr.indexOf(reqarr.filter((a) => a === "description")[0]),
      1
    );
    reqarr.splice(
      reqarr.indexOf(reqarr.filter((a) => a === "studyType")[0]),
      1
    );
    reqarr.splice(reqarr.indexOf(reqarr.filter((a) => a === "sites")[0]), 1);
    reqarr.splice(
      reqarr.indexOf(reqarr.filter((a) => a === "detailedDescription")[0]),
      1
    );
    //log(`${reqarr},'reqarr'`)
    return ["overallStatus", "phase", "studyType", ...reqarr];
  }
};

const removeRepeatedElements = (arr) => {
  let obj = {},
    count;
  //log(`${s},'s'`);
  //now we will find how many times each item is repeated
  return new Promise((resolve, reject) => {
    if (arr && arr.length > 0) {
      arr.map((prop) => {
        //log(`${prop},'prop'`);
        count = obj[prop] || 0;
        obj[prop] = count + 1;
        //log(`${obj.prop},'obj[prop]'`)
      });
      resolve(obj);
    } else {
      reject(obj);
    }
  });
};

const getRepeatedKeyValues = (obj, num) => {
  return new Promise((resolve, reject) => {
    if (obj === null || undefined) {
      reject({ error: "true", message: "Object is empty" });
    } else {
      let repeatedArray = Object.entries(obj),
        result = {};
      repeatedArray.map((r) => {
        //log(`${JSON.stringify(r)},'r'`)
        //checking for repeated value greater than 1
        if (r && r[1] > num) {
          //to return object with all the filtered list of items whose value is greater than 1
          result[r[0].split(",")[0]] = repeatedArray
            .filter((fi) => fi[1] > num && fi[0].startsWith(r[0].split(",")[0]))
            .map((ci) => {
              //log (`${ci},'ci'`)
              return {
                name: ci[0].split(",")[1],
                val: ci[1],
              };
            });
        }
      });
      resolve({
        checkboxitems: Object.entries(obj).filter(
          (list) => list && list[1] > 1
        ),
        resultdata: result,
      });
    }
  });
};
//to push all objects key value pairs into array
const getAllElements = (arr) => {
  log(`"invoking", ${arr}`);
  let all = [];
  return new Promise((resolve, reject) => {
    if (arr && arr.length > 0) {
      arr.map((item) => {
        //log(a);
        let valuearr = Object.entries(item);
        valuearr.map((v) => {
          //log(`${v},'v'`);
          all.push(v);
        });
      });
      resolve(all);
    }
    reject("arr value zero");
  });
};

const getTitle = (type, trial) => {
  if (type === "ts") {
    return <h3 className="py-2">{trial && trial.title}</h3>;
  } else if (type === "ps") {
    return <h3 className="py-2">{trial && trial.brief_title}</h3>;
  }
};

const getPhase = (type, trial) => {
  if (type === "ts") {
    return <span className="mx-1">{trial && trial.phase}</span>;
  } else if (type === "ps") {
    log(
      `${type},
      ${trial},
      ${trial && trial.nct_number},
      ${trial && trial.study_phase},
      "phase-helper"`
    );
    return (
      <span className="mx-1">
        {trial && trial.study_phase && trial.study_phase.length > 0
          ? trial.study_phase
          : trial && trial.nct_number && trial.nct_number.length > 0
          ? trial.nct_number
          : "None"}
      </span>
    );
  }
};

const getConditions = (type, trial) => {
  if (type === "ts") {
    return trial && trial.conditions && trial.conditions.length > 0 ? (
      trial.conditions.length < 2 ? (
        trial.conditions.map((disease, i) => (
          <span key={i} className="">
            {disease}
          </span>
        ))
      ) : (
        <span>{`${JSON.parse(trial.conditions)[0]}, ${
          trial.conditions.length - 1
        } more ...`}</span>
      )
    ) : (
      <span className="">{"None"}</span>
    );
  } else if (type === "ps") {
    log(`${trial}, "pstrial"`);
    return (
      <span className="mx-1">
        {trial && trial.conditions && trial.conditions.length > 0
          ? trial.conditions
          : trial && trial.study_name && trial.study_name.length > 0
          ? trial.study_name
          : "None"}
      </span>
    );
  }
};

const getTenResults = (count, arr) => {
  log(`${count}, ${arr}, "checking getTenResults function"`);
  let splicedArray = arr.splice(count * 10 - 10, 10);
  log(`${splicedArray}, "after splicing"`);
  return splicedArray;
};

const validateEmail = (email) => {
  let emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  log(`${emailRegex.test(email)}`);
  if (email.indexOf(".") == "-1") {
    return false;
  } else if (email.startsWith(".") === true) {
    return false;
  }
  return emailRegex.test(email);
};

const checkForLettersOnly = (text) => {
  log(text, "text received");
  let characters = /^[a-zA-Z]+$/;
  log(characters.test(text), "check for only letters");
  return characters.test(text);
};

const getLocations = (location) => {
  let address =
    location.split(" ") && location.split(" ").length > 0
      ? location.split(" ").join("+")
      : location;
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/backend/locations?address=${address}`
      )
      .then((s) => {
        log(`${s.data}, "geolocation successful"`);
        resolve(s.data);
      })
      .catch((e) => {
        log(`${e}, "error with getting our studies"`);
        reject("err with geocoding");
      });
  });
};

const getZipcodes = (userzipcode, country, nearestzipcodes) => {
  return new Promise((resolve, reject) => {
    log(`${nearestzipcodes},"nearest zips"`);
    axios
      .get(
        `/backend/zipcodes?user_zipcode=${userzipcode}&country=${country}&nearest_zipcodes=${JSON.stringify(
          nearestzipcodes
        )}`
      )
      .then((success) => {
        log(`${success.data},'success'`);
        resolve(success.data);
      })
      .catch((fail) => {
        log(`${fail},'err with getting zipcodes'`);
        reject([]);
      });
  });
};

const getClinicalTrials = (condition) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/backend/clinicaltrials?q=" + condition)
      .then((ctstudies) => {
        //log(ctstudies.data.output,'ct studies');
        resolve(ctstudies.data.output);
      })
      .catch((errCtstudies) => {
        //log(errCtstudies,'err with fetching ctg studies');
        reject([]);
      });
  });
};

const getInclusionCriteria = (eligibility) => {
  if (eligibility && eligibility.length > 0) {
    let inclusionTerms =
      "Inclusion Criteria" || "INCLUSION CRITERIA" || "inclusion criteria";
    let exclusion =
      "Exclusion Criteria" || "EXCLUSION CRITERIA" || "exclusion criteria";
    if (
      eligibility.split(exclusion) &&
      eligibility.split(exclusion).length > 0
    ) {
      let getInclusion = eligibility.split(exclusion)[0];
      return getInclusion.replace(inclusionTerms, "");
    } else {
      return "None";
    }
  } else {
    return "None";
  }
};

const getExclusionCriteria = (eligibility) => {
  if (eligibility && eligibility.length > 0) {
    let exclusion =
      "Exclusion Criteria" || "EXCLUSION CRITERIA" || "exclusion criteria";
    if (
      eligibility.split(exclusion) &&
      eligibility.split(exclusion).length > 0
    ) {
      let getInclusion = eligibility.split(exclusion)[1];
      return getInclusion.replace(exclusion, "");
    } else {
      return "None";
    }
  } else {
    return "None";
  }
};

const enrollLink = (number) => {
  return (window.location.href = "/enroll/" + number);
};


export {
  getAllElements,
  removeRepeatedElements,
  getRepeatedKeyValues,
  getPhase,
  getTitle,
  getConditions,
  getLocationDetails,
  location,
  changeSidebarFilters,
  getTenResults,
  validateEmail,
  countries,
  disableEnrollButton,
  cmvFaqs,
  melanomaFaqs,
  covidFaqs,
  schizophreniaFaqs,
  colorectalCancerFaqs,
  checkForLettersOnly,
  getLocations,
  getZipcodes,
  log,
  iconUrl,
  getClinicalTrials,
  getInclusionCriteria,
  getExclusionCriteria,
  enrollLink
};
