import React, { useState } from "react";
import { PopularSearch} from "../../components";
import { countries } from "../../helpers";
import "../../App.css";
import {
  SearchIcon,
  SearchLightIcon,
  iAboutUs,
  Down,
  arrowRight,
  step1,
  step2,
  step3,
  step4,
  step5,
  ReviewImg1,
  quotationIcon,
  review_slide_active,
  review_slide_inactive,
  StoreIcons,
  Iphone,
  Partnering,
  trialscope_main_logo,
  twilio_logo,
  service_now_logo,
  findTrial,
  EnrollInStudy,
  EligibilityScreening,
  ConsentedProcess,
  start_the_trial,
  what_are_users_saying,
  partner_with_us,
  i_search_black,
} from "../theme/icons";

const Reviews = [
  {
    id: 0,
    feedback:
      "My wife has a chronic kidney disease and she was diagnosed with a thyroid cancer recently. We were able to find a trial in few days on Trials.me. Since then, my wife has been actively in touch with study team. When needed, we always have someone ready to answer on PatienTrials App and knowing what to expect, her energy goes up. Trials.me changed our life quality and we are much more happier now. I will recommend to other patients for sure!",
    img: what_are_users_saying,
    patientName: "Jacob Jones",
    role: "Patient",
  },
  {
    id: 1,
    feedback:
      "I was suffering from Jaw pain with dry mouth and tried multiple treatments with no result. I signed up for a study match through PatienTrials and they were able to help me find the right study in couple of weeks. Effective treatment depends on getting the right diagnosis as soon as possible with less impact on your out of pocket expenses. The PatienTrials App was critical to my treatment as I was able share and get a quick feedback from the study coordinators on my sporadic conditions. This helped me a lot to take care of my condition. Now that I'm free from my Jaw pain and dry mouth, I recommend other patients like me find the right study for their condition at Trials.me",
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTY0J5j_EaLOFVzjvaB2qnqFVOr5Fghm-EuMQ&usqp=CAU",
    patientName: "Patrick Hughes",
    role: "Patient",
  },
  // {
  //   id: 2,
  //   feedback:
  //     "when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //   img:
  //     "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSnY9tlaExmXctCs1YG8F-NBqNQeE0uejVN4g&usqp=CAU",
  //   patientName: "Willam Jones",
  //   role: "CRO",
  // },
];

const HomeView = (props) => {
  const {
    handleChange,
    sendInfo,
    condition,
    country,
    onOptionClick,
    conditionError,
    countryError,
    history,
    hideSpacing,
    setCondition,
    suggestions,
    handleSearchKeyDown,
    showChat
  } = props;
  const [reviewItem, setReviewItem] = useState(0);

  const dotClick = (id) => {
    setReviewItem(id);
  };

  const ProcessStep = ({ img, title, description, leftWidth, rightWidth }) => {
    return (
      <div className="row my-2">
        <div style={{ textAlign : 'left'}} className={`${leftWidth} p-0`}>
          <img src={img} alt={"step"}></img>
        </div>
        <div className={`${rightWidth} text-left py-4`}>
          <h3 className="process_title">{title}</h3>
          <p
            className="process_desc"
          >
            {description}
          </p>
        </div>
      </div>
    );
  };

  const Review = ({ img, feedback, patientName, role }) => {
    return (
      <div className="row feedback mb-4">
        <div style={{ textAlign : 'left'}} className="col-sm-12 col-md-12 col-lg-6 mx-0 p-0 ">
          <img className="mr-0 img-fluid w-100" alt={"user"} src={img}></img>
        </div>
        <div style={{ background: '#034EA2 0% 0% no-repeat padding-box', opacity:0.8 }} className="col-sm-12 col-md-12 col-lg-6 text-left p-3 px-5 feedback_right">
          {/* <img src={quotationIcon} alt={"quotation"}></img> */}
          <h4 className="quotation mb-0">“</h4>
          <h3 className="pl-1">{feedback}</h3>
          <h3 className="mt-4 mb-0">{patientName}</h3>
          <h3>{role}</h3>
        </div>
      </div>
    );
  };

  return (
    <div>
      {/* <div className="container p-0">
        <Warning subtitle={"Please send your feedback to hello@patientrials.com"} />
      </div> */}
      <div id="find_trial">
          <div className="container-fluid px-0 mx-0 find_trial_bg w-100 d-flex align-items-center justify-content-center">
              <div className="container px-0 mx-5 mx-lg-0 mx-md-0 content_search">
                  <div className="p-4">
                    <h3>Search Clinical Trials</h3>
                    <h6 className="mt-4">I am looking for</h6>
                    <div className="row px-0 mx-0">
                        <div className="col-lg-6 px-0 pr-2">
                            <div className="search_input d-flex align-items-center justify-content-center p-1">
                              <img
                                style={{ margin: "0px 0px" , height : 30 , width : 30  }}
                                alt={"search icon"}
                                src={i_search_black}
                              ></img>
                              <input
                                style={{width: '100%', border: 'none', outline: 'none', height: 30}}
                                type="text"
                                name="condition"
                                autoComplete="off"
                                onChange={handleChange}
                                onKeyDown={handleSearchKeyDown}
                                value={condition}
                                placeholder="Condition by keyword"
                              ></input>
                            </div>
                            {conditionError && conditionError === true ? <p style={{fontSize: 11, fontFamily : 'NunitoRegular', color : 'red'}} className="mb-0">Please enter the desired condition</p> : null}
                            {suggestions && suggestions.length > 0 ? 
                              <div className="suggestion-box d-block align-items-start justify-content-center col-lg-10 col-md-10 col-sm-10 col-xs-10 mx-0 px-0">
                              {condition && condition.length > 0 ?
                              suggestions.map(suggestion=>
                                <div onClick={()=>setCondition(suggestion)}>
                                    <p className={`px-4 py-2 mb-0 mx-0 ${suggestion === condition ? "selected_suggestion" : ""}`}>{suggestion}</p>
                                </div> 
                              ) : null}
                              </div>
                            : null}
                        </div>
                        <div className="col-lg-3 search_input p-2 input_country">
                            <p className="mb-0 pl-1">Country</p>
                            <select
                                style={{width : '100%', border : 'none', outline : 'none'}}
                                className="text-center"
                                onChange={handleChange}
                                name="country"
                                value={country}
                              >
                                {countries.map((country, i) => (
                                  <option
                                    key={i}
                                    style={{ fontSize: "16px"}}
                                    className="text-left"
                                    value={country}
                                  >
                                    {country}
                                  </option>
                                ))}
                            </select>
                        </div>
                        
                        <div className="col-lg-3 pr-0">
                            <button
                              onClick={() => sendInfo(country , condition)}
                              rel="noopener noreferrer"
                              className="gn_btn btn btn-primary btn-lg w-100 content_search_button"
                            >
                              FIND MY TRIAL
                            </button>
                        </div>
                    </div>
                    <h6 className="mt-3">Popular Search</h6>
                    <PopularSearch history={history} onOptionClick={onOptionClick} />
                  </div>
              </div>
          </div>
      </div>
      <div style={{backgroundColor : '#F9F9F9'}}>
          <div className="container text-center px-2 px-lg-0 mb-5 how_it_works">
            <div className="text-left">
              <h2
                style={{}}
                className="main_page_subtitle mb-0"
              >
                HOW IT WORKS
              </h2>
              <p
                style={{
                  
                }}
                className="main_page_title"
              >
                Enrollment Process
              </p>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6 m-0 p-0">
                <ProcessStep
                  img={findTrial}
                  leftWidth={"col-sm-12 col-md-5 col-lg-5"}
                  rightWidth={"col-sm-12 col-md-7 col-lg-7"}
                  title={"1.Find a Trial"}
                  description={
                    "Enter your condition and country, then filter in the search result page based on age, trial phase and others."
                  }
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 m-0 p-0">
                <ProcessStep
                  img={EnrollInStudy}
                  leftWidth={"col-sm-12 col-md-5 col-lg-5"}
                  rightWidth={"col-sm-12 col-md-7 col-lg-7"}
                  title={"2.Enroll in Study"}
                  description={
                    "Review the enrollment criteria and self assess if you meet the minimal requirement. Fill out enrollment form, which include medical condition, contact info, family history, etc."
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6 m-0 p-0">
                <ProcessStep
                  img={EligibilityScreening}
                  leftWidth={"col-sm-12 col-md-5 col-lg-5"}
                  rightWidth={"col-sm-12 col-md-7 col-lg-7"}
                  title={"3.Eligibility Screening"}
                  description={
                    "Our clinical research coordinators will review your application carefully and contact you with next steps in email and/or call."
                  }
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 m-0 p-0">
                <ProcessStep
                  img={ConsentedProcess}
                  leftWidth={"col-sm-12 col-md-5 col-lg-5"}
                  rightWidth={"col-sm-12 col-md-7 col-lg-7"}
                  title={"4.Consented Process"}
                  description={
                    "Clinical research coordinator will go over the trial details and you will have time to consult your health care providers and loved one before taking the decision."
                  }
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 m-0 p-0">
                <ProcessStep
                  img={start_the_trial}
                  leftWidth={"col-sm-12 col-md-5 col-lg-5"}
                  rightWidth={"col-sm-12 col-md-7 col-lg-7"}
                  title={"5.Start the Trial"}
                  description={
                    "After signing the consent you will be assigned to a professional to answer your questions on the app through out the journey. Trial period will vary based on study."
                  }
                />
              </div>
            </div>
          </div>
      </div>
      {/* mapsection */}
      <div className="metrics">
          <div className="container py-3">
              <div className="row py-5">
                <div className="col-sm-12 col-md-12 col-lg-4 px-0">
                  <p className="title mb-0">
                    Don't miss the treatment prime time. All trials are free for
                    patients and participants
                  </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-8 px-0">
                  <div className="row px-0 mx-0">
                      <div className="col-lg-3 pl-5">
                        <h6 className="mb-1 nums">200+</h6>
                        <p className="mb-0 subtitle">Trials Sourced</p>
                      </div>
                      <div className="col-lg-3 pl-4">
                        <h6 className="mb-1 nums">100,000+</h6>
                        <p className="mb-0 subtitle">Trials Sourced</p>
                      </div>
                      <div className="col-lg-3 pl-5">
                        <h6 className="mb-1 nums">3500+</h6>
                        <p className="mb-0 subtitle">Trials Sourced</p>
                      </div>
                      <div className="col-lg-3 pl-5">
                        <h6 className="mb-1 nums">250+</h6>
                        <p className="mb-0 subtitle">Trials Sourced</p>
                      </div>
                  </div>
                </div>
              </div>
          </div>
      </div>
      {/* review section */}
      <div className="container">
        <div className="reviews my-5 mt-2 pb-1">
          <h3 className="main_page_subtitle mb-0">REVIEWS</h3>
          <h2 className="main_page_title">What Our Users Are Saying</h2>
          <div className="">
            <Review {...Reviews[reviewItem]} />
          </div>
        </div>
      </div>
      {/* about section */}
      <div className="container">
          <div className="row px-0 mx-0">
            <div className="aboutus_left col-sm-12 col-md-12 col-lg-6 px-0 mx-0">
              <div className="container mt-0 px-0">
                <h2
                  className="main_page_subtitle"
                >
                  ABOUT US
                </h2>
                <h3
                  className="main_page_title"
                >
                  We are the Leading Patient Engagement Platform for Clinical Trials
                </h3>
                <br></br>
                <p
                  className="mb-0 about_us_desc"
                >
                  PatienTrials empowers clinicians and sponsors to run global
                  clinical trials virtually or through selected sites, more
                  efficiently in a closed community setting over HIPAA & GDPR
                  compliant collaboration platform.
                </p>
                <br></br>
                <a
                    style={{ textDecoration: "none" }}
                    href="https://patientrials.com/request-demo/"
                >
                    <h2
                      className="about_us_text mb-0"
                    >
                      Learn more about us
                    </h2>
                </a>
              </div>
            </div>
            <div className="aboutus_right col-sm-12 col-md-12 col-lg-6 px-0 mx-0">
              <img className="w-100" src={iAboutUs} alt="about_us"></img>
            </div>
          </div>
      </div>
      {/* partners section */}
      {/* <div className="mx-auto py-4 text-center container partners">
        <h3>Backed By Robust Partners</h3>
        <p className="mx-4 mb-0">
          We partnered with the most prestigious organizations to streamline the
          process, and reduce the risk of study.{" "}
        </p>
        <div className="mt-0 row d-flex align-items-center justify-content-center">
          <img
            className="col-md-4 img-fluid"
            src={service_now_logo}
            alt={"partner1"}
          ></img>
          <img
            className="col-md-4 img-fluid"
            src={twilio_logo}
            alt={"partner1"}
          ></img>
          <img
            className="col-md-4 img-fluid"
            src={trialscope_main_logo}
            alt={"partner1"}
          ></img>
        </div>
      </div> */}
      {/* sponsors and clinical trials */}
      <div className="container my-5 ">
          <div className="row px-0 mx-0">
              <div className="col-lg-6 px-0">
                  <img className="w-100" src={partner_with_us} alt="partner_with_us"></img>
              </div>
              <div style={{backgroundColor : "#F9F9F9"}} className="col-lg-6 px-0 d-flex flex-column align-items-start justify-content-center px-5">
                  <h3 className="mb-0 main_page_subtitle">PARTNER WITH US</h3>
                  <h6 className="my-4 main_page_title">We are the Leading Patient Engagement Platform for Clinical Trials</h6>
                  <a href="https://patientrials.com/request-demo/"><button className="btn btn-lg dt_btn">GET IN TOUCH</button> </a>
              </div>
          </div>
      </div>
      {/* show chat */}
      {/* <div className="chat d-flex align-items-center justify-content-end">
          <div style={{position: 'fixed',bottom :20,right : 20,height:100, width:100, borderRadius: 50, backgroundColor:'dodgerblue'}}></div>
      </div> */}
    </div>
  );
};

export default HomeView;
