import React from "react";
import { Domain, environment } from "../../constants";
import { getTrialId } from "../../helpers";
import axios from "axios";
import ThankYou from "./ThankYou";
import Participant from "./Participant";
import { checkForLettersOnly, log, validateEmail } from "../../helpers/helper";
import { getQuestionGroup } from "../../helpers";
import { v4 as uuidv4 } from "uuid";

const disabledPromoTrials = ["b58e6fccdba8e4505ca4bc04b99619df"];

class EnrollmentController extends React.Component {
  constructor(props) {
    super();
    this.country = window.sessionStorage.getItem("country");
    this.condition = window.sessionStorage.getItem("condition");
    this.race = {};
    this.state = {
      type: "",
      isLoading: false,
      showEmailError: false,
      showFirstNameError: false,
      showLastNameError: false,
      phase: "",
      race: "",
      conditions: [],
      condition: "",
      studyId: "",
      firstname: "",
      nctNumber: "",
      lastname: "",
      email: "",
      country: "UNITED STATES",
      showRegisterOptions: false,
      city: "",
      participant_id: "",
      zipcode: "",
      gender: "",
      dob: "",
      phone: "",
      smokingstatus: "",
      primarylanguage: "",
      currentmedication: "",
      healthcondition: "",
      trial: null,
      familyhistory: "",
      stateterritory: "",
      timezone: "",
      patient_id: "",
      pt_uname: "",
      domain: "",
      isParticipantEnrolled: false,
      patientSysId: "",
      showModal: false,
      showAcknowledgement: false,
      loadingForModal: false,
      criteria: "",
      currentpage: "",
      questions: [],
      showPrescreening: false,
      show_patient_id: "false",
      recaptcha_response : ""
    };
    window.localStorage.removeItem("messages")
    window.localStorage.setItem("enableChat", false)
  }
  onTextInputChange = (e) => {
    log(`${e.target.name}, ${e.target.value}, ${e.target}`);
    this.setState({ [e.target.name]: e.target.value }, () => {
       if (
        this.state.email !== null ||
        this.state.email !== "" ||
        this.state.email !== undefined
      ) {
        let checkEmail = validateEmail(this.state.email);
        if (checkEmail === false) {
          this.setState({ showEmailError: true });
        } else if (checkEmail === true) {
          this.setState({ showEmailError: false });
        }
      }
    });
  };
  optionChange = (e) => {
    log(`${e.target.name}, ${e.target.value}`);
    this.setState({ [e.target.name]: e.target.value });
  };
  howManyQuestionsAnswered = (questions) => {
    let checkAnswer = [];
    if (questions && questions.length > 0) {
      questions.map((questionID) =>
        checkAnswer.push(this.state.hasOwnProperty(questionID))
      );
      return checkAnswer;
    }
  };

  submitAnswers = () => {
    let questions = this.state.questions;
    if (questions && questions.length > 0) {
      this.state.questions.map((ques) => {
        log(this.state[ques.sys_id], "for submitting");
        return axios
          .post(
            "/backend/submit",
            {
              answer: this.state[ques.sys_id],
              sys_id: ques.sys_id,
              order: ques.order,
              participant_id: this.state.participant_id,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((suc) => {
            log(`${suc}, "submitted answers"`);
            this.setState({ showPrescreening: false });
          })
          .catch((e) => {
            this.setState({ showPrescreening: false });
          });
      });
    } else {
      log(questions, "no questions found");
      return;
    }
  };

  onRecaptchaChange = (value) =>{
    log(value,'value recaptcha')
    this.setState({ recaptcha_response : value})
  }

  onEnroll = () => {
    if (this.state.questions && this.state.questions.length > 0) {
      let question_ids = this.state.questions.map((id) => id.sys_id);
      if (
        this.howManyQuestionsAnswered(question_ids) &&
        this.howManyQuestionsAnswered(question_ids).filter(
          (answer) => answer === false
        ) &&
        this.howManyQuestionsAnswered(question_ids).filter(
          (answer) => answer === false
        ).length > 0
      ) {
        alert("Please enter all pre-screening questions");
      } else {
        this.setState({ showPrescreening: false });
      }
    } else {
      this.setState({ showPrescreening: false });
    }
    this.setState({ showRegisterOptions: true });
  };

  getRandomNumber = () => {
    let random_id = uuidv4();
    log(random_id, "random number generated");
    return random_id;
  };

  alignPrescreeningResponses = () => {
    let responses = [];
    let questions = this.state.questions;
    if (questions && questions.length > 0) {
      questions.map((question) => {
        let response = {
          question: question.question,
          sys_id: question.sys_id,
          answer: this.state[question.sys_id],
        };
        return responses.push(response);
      });
      log(responses, "all responses");
      if (responses && responses.length > 0) {
        window.localStorage.setItem("prescreening", JSON.stringify(responses));
      }
    } else {
      log(questions, "no questions found");
    }
  };

  navigateOption = (title) => {
    if (title === "Enter Manually") {
      this.setState({ showRegisterOptions: false });
    } else if (title === "Connect My EMR") {
      let randomNumber = this.getRandomNumber();
      if (randomNumber && randomNumber.length > 0) {
        window.localStorage.setItem("nct_id", this.state.nctNumber);
        window.localStorage.setItem("prescreening", this.state.questions);
        window.localStorage.setItem("study_number", this.state.studyId);
        this.alignPrescreeningResponses();
        setTimeout(() => {
          window.location.href = `/connect-to-emr/${randomNumber}/${this.state.studySysId}/${this.state.domain}/${this.state.studyId}`;
        }, 1500);
      } else {
        log(randomNumber, "err generating random number");
        alert("Error occured. Please try again");
      }
    } else if (title === "Upload Document") {
      this.alignPrescreeningResponses();
      setTimeout(() => {
        window.location.href = `/upload-document/${this.props.match.params.studyId}`;
      }, 1500);
    }
  };

  onRaceCheckboxChange = (e, qid) => {
    if (e.target.checked) {
      log(`${e.target.checked}, ${e.target.value}, "checking race inputs"`);
      if (e.target.type === "checkbox") {
        this.race.hasOwnProperty("race")
          ? this.race["race"].push(e.target.value)
          : (this.race["race"] = [e.target.value]);
      } else {
        this.race.hasOwnProperty("race")
          ? this.race["race"].push(e.target.value)
          : (this.race["race"] = [e.target.value]);
      }
    } else {
      if (e.target.type === "checkbox") {
        log(`${e.target.checked}, ${e.target.value}, "checking race inputs"`);
        if (this.race.hasOwnProperty("race")) {
          let arr = this.race["race"].indexOf(e.target.value);
          this.race["race"].splice(arr, 1);
        }
      }
    }
    log(`${this.race}, "in race oncheckbox"`);
    this.setState({ race: this.race["race"].toString() });
  };

  

  signUp = () => {
    const {
      city,
      firstname,
      lastname,
      email,
      gender,
      phone,
      dob,
      race,
      smokingstatus,
      primarylanguage,
      studySysId,
      country,
      zipcode,
      stateterritory,
      timezone,
      patient_id,
      currentmedication,
      healthcondition,
      familyhistory,
      nctNumber,
      domain,
      criteria,
      currentpage,
      showModal,
      participant_id,
      studyId,
      recaptcha_response
    } = this.state;
    let checkEmptyValues = [
      firstname,
      lastname,
      city,
      country,
      zipcode,
      email,
      gender,
      phone,
      dob,
      smokingstatus,
      primarylanguage,
      race,
      healthcondition,
      recaptcha_response
    ];
    let check = checkEmptyValues.filter(
      (item) => item === "" || null || undefined
    );
    if (check && check.length > 0) {
      log(`${check}, "check"`);
      alert("please enter all required values");
    } else {
      log("do further");
      this.setState({ showAcknowledgement: true });
      let request_body = {
        recaptcha_response : recaptcha_response,
        preferred_name: firstname,
        last_name: lastname,
        gender: gender,
        date_of_birth: dob,
        email: email,
        phone_number: phone,
        race: race.toString(),
        study: studySysId,
        language: primarylanguage,
        country: country,
        zip_code_or_city: zipcode,
        state_territory: stateterritory,
        time_zone: timezone,
        domain: domain,
        patient_id: patient_id,
        nct_number: nctNumber,
        current_medications: currentmedication,
        health_condition: healthcondition,
        relevant_family_history: familyhistory,
        smoking_status: smokingstatus,
        guest_id: participant_id,
        other_information:
          criteria === null ? currentpage : criteria + currentpage,
        prescreening_qa: "",
        sys_domain: domain,
        study_number: studyId,
      };
      // (environment === "DEV" || environment === "STAGING") ? request_body["sys_domain"] = domain : log("PROD")
      log(`${request_body}, "request body"`);
      log(request_body, 'body');
      axios
        .post("/backend/participant", request_body)
        .then((success) => {
          log(`${success.status},'then'`);
          if (
            (success && success.status === 201) ||
            (success && success.status === 200)
          ) {
            log(`${success && success.data.result}, "created participant"`);
            this.setState(
              {
                patient_id:
                  success &&
                  success.data &&
                  success.data.pt_id,
              },
              () => {
                this.setState({ showModal: !showModal });
                let patientSysId =
                  success &&
                  success.data &&
                  success.data.pt_sys_id;
                let uName =
                  success &&
                  success.data &&
                  success.data.u_id;
                this.setState(
                  { patientSysId: patientSysId, pt_uname: uName },
                  () => {
                    this.setState({ participant_id: patientSysId }, () => {
                      window.localStorage.setItem("pt_sys_id", patientSysId)
                      this.submitAnswers();
                    });
                    this.setState({ showModal: false, loadingForModal: true });
                    //this.setState({ isParticipantEnrolled: true });
                  }
                );
              }
            );
          } else if (
            success &&
            success.data &&
            success.data.failure === "failure"
          ) {
            let participantError =
              success &&
              success.data &&
              success.data.error &&
              success.data.error.message;
            alert(participantError + "Error while creating participant");
            this.setState({
              loadingForModal: false,
              showAcknowledgement: false,
            });
          } else {
            log(`${success.status},'then'`);
            alert("Error while creating participant");
            this.setState({
              loadingForModal: false,
              showAcknowledgement: false,
            });
          }
        })
        .catch((errSuccess) => {
          // log(
          //   `${errSuccess},${errSuccess.status}, "error creating participant"`
          // );
          log(errSuccess,errSuccess.response ,'err while creating participant');
          if (
            errSuccess &&
            errSuccess.response &&
            errSuccess.response.status === 400
          ) {
            alert("Error while creating participant");
            this.setState({
              loadingForModal: false,
              showAcknowledgement: false,
            });
          } else if (
            errSuccess &&
            errSuccess.response &&
            errSuccess.response.status === 403
          ) {
            alert(
              "Error while creating participant looks like email already exists"
            );
            this.setState({
              loadingForModal: false,
              showAcknowledgement: false,
            });
          }
        });
    }
  };

  onAcknowledge = (status) => {
    axios
      .post("/backend/acknowledge", {
        sysId: this.state.patientSysId,
        acknowledged: status,
      })
      .then((successAck) => {
        log(`${successAck && successAck.data}, "on acknowledge"`);
        this.setState(
          {
            isParticipantEnrolled: true,
            showModal: false,
            showAcknowledgement: false,
          },
          () => {
            if (status === "false") {
              window.location.href = "/";
            } else {
              if (
                this.state.trial &&
                this.state.trial.account_sid &&
                this.state.trial.account_sid !== undefined &&
                this.state.trial &&
                this.state.trial.account_sid &&
                this.state.trial.account_sid.length > 0
              ) {
                window.localStorage.setItem("chat_status", "started");
                window.localStorage.setItem(
                  "prospectID",
                  this.state.patient_id
                );
                window.localStorage.setItem("f_name", this.state.firstname);
                window.localStorage.setItem("city", this.state.city);
                window.localStorage.setItem("dob", this.state.dob);
                window.localStorage.setItem("gender", this.state.gender);
                window.location.href = `/thankyou/${this.state.patient_id}`;
              } else {
                window.location.href = `/thankyou/${this.state.patient_id}`;
              }
            }
          }
        );
      })
      .catch((errSuccessAck) => {
        log(`${errSuccessAck}, "error while acknowledgement"`);
        this.setState({
          isParticipantEnrolled: false,
          showModal: false,
          showAcknowledgement: false,
        });
      });
  };
  

  componentDidMount() {
    let { studyId } = this.props.match.params;
    let decodedString = decodeURIComponent(this.props.location.pathname);
    let decodeSearchString = decodeURIComponent(this.props.location.search);
    log("Hello", "world", 42);
    let city = window.sessionStorage.getItem("city");
    let state = window.sessionStorage.getItem("state");
    let country = window.sessionStorage.getItem("country");
    let doc_upload_status = window.localStorage.getItem("doc_upload_status");
    if (doc_upload_status !== null) {
      this.setState({ showPrescreening: false });
      let details = JSON.parse(doc_upload_status);
      this.setState({
        country: details.country,
        city: details.city,
        zipcode: details.zipcode,
        firstname: details.firstname,
        lastname: details.lastname,
        email: details.email,
        gender: details.gender,
        dob: details.dob,
        phone: details.phone,
        conditions: details.conditions,
        medications: details.medications,
        primarylanguage: details.primarylanguage,
      });
    } else {
      this.setState({
        city: city === null ? "" : city,
        state: state === null ? "" : state,
        country:
          country === "" ||
          country === undefined ||
          country === null ||
          country === "US" ||
          country === "United States of America" ||
          country === "United States Of America" ||
          country === "UNITED STATES OF AMERICA"
            ? "UNITED STATES"
            : country,
      });
      // log(
      //   `${localStorage.getItem("criteria")},${localStorage.getItem(
      //     "currentPage"
      //   )}, "checking localstorage for criteria"`
      // );
      let criteria = localStorage.getItem("criteria");
      let current_page = localStorage.getItem("currentPage");
      if (criteria !== undefined || criteria !== null) {
        this.setState({ criteria: criteria, currentpage: current_page });
      } else {
        this.setState({ currentpage: current_page });
      }
      this.setState(
        {
          studyId: studyId,
        },
        () => {
          window.localStorage.clear();
          window.localStorage.removeItem("twilio-flex-cf");
          window.localStorage.removeItem("loglevel:twilio-sync");
          window.localStorage.removeItem("loglevel:twilsock");
          window.localStorage.removeItem("loglevel:twilio-flex-webchat-ui");
          window.localStorage.removeItem("loglevel");
          window.localStorage.removeItem("loglevel:twilio-chat");
          window.localStorage.removeItem("study_nct")
          window.localStorage.removeItem("study_sid")
          window.localStorage.removeItem("acc_sid");
          window.localStorage.removeItem("pt_sys_id");
          window.localStorage.removeItem("flow_id");
          window.localStorage.removeItem("study_nct")
                  window.localStorage.removeItem("study_sid")
          window.sessionStorage.clear();
          this.getTrialUsingStudyId();
        }
      );
    }
  }

  componentWillUnmount() {
    this.setState({ participant_id: "" });
  }

  getTrialUsingStudyId = () => {
    getTrialId(this.state.studyId)
      .then((gotStudy) => {
        // log(
        //   `${gotStudy},
        //   "got required study with id and type during enroll"`
        // );
        this.setState(
          {
            trial: gotStudy && gotStudy.trial,
          },
          () => {
            this.setState(
              {
                domain: this.state.trial && this.state.trial.domain,
                studySysId: this.state.trial && this.state.trial.study_sys_id,
                nctNumber: this.state.trial && this.state.trial.nct_number,
                show_patient_id:
                  (this.state.trial && this.state.trial.show_patient_id) ||
                  "false",
              },
              () => {
                window.localStorage.setItem("nctId", this.state.nctNumber);
                if (
                  this.state.trial &&
                  this.state.trial.account_sid &&
                  this.state.trial.account_sid !== undefined &&
                  this.state.trial &&
                  this.state.trial.account_sid &&
                  this.state.trial.account_sid.length > 0
                ) {
                  window.localStorage.setItem("study_nct", this.state.trial && this.state.trial.nct_number)
                  window.localStorage.setItem("study_sid", this.state.trial && this.state.trial.study_sys_id)
                  window.localStorage.setItem(
                    "acc_sid",
                    this.state.trial && this.state.trial.account_sid
                  );
                  window.localStorage.setItem(
                    "flow_id",
                    this.state.trial && this.state.trial.flex_flow_id
                  );
                }
                getQuestionGroup(this.state.studySysId, this.state.studyId)
                  .then((qG) => {
                    log(`${qG}, "qG"`);
                    this.setState({ questions: qG && qG.questions }, () => {
                      if (
                        this.state.questions &&
                        this.state.questions.length > 0
                      ) {
                        this.setState({
                          isLoading: true,
                          //participant_id: Date.now()+"-"+ this.getRandomNumber() ,
                          showPrescreening: true,
                        });
                      } else {
                        this.setState({
                          isLoading: true,
                          //participant_id: "",
                          showPrescreening: false,
                          showRegisterOptions: true,
                        });
                      }
                    });
                  })
                  .catch((errQG) => {
                    log(`${errQG}, "errQG"`);
                    this.setState({ questions: [], isLoading: true });
                  });
              }
            );
          }
        );
      })
      .catch((errGotStudy) => {
        // log(
        //   `${errGotStudy},
        //   "cannot get study details using trial id and type during enroll"`
        // );
        this.setState(
          {
            trial: errGotStudy && errGotStudy.trial,
            showRequirements: errGotStudy && errGotStudy.showRequirements,
          },
          () => {
            this.setState({
              domain: this.state.trial && this.state.trial.domain,
            });
          }
        );
      });
  };

  render() {
    const { isParticipantEnrolled } = this.state;
    //const {study_phase,brief_title} = this.state.trial
    return (
      <div>
        <div
          className={`${
            this.props.location &&
            this.props.location.search &&
            this.props.location.search.indexOf("embed=true") !== -1
              ? ""
              : "p-0 pt-5 mt-4"
          }`}
        >
          {/* <Warning /> */}
          <Participant
            // ref={this.recaptcha_ref}
            {...this.state}
            navigateOption={this.navigateOption}
            disabledTrials={disabledPromoTrials}
            onTextInputChange={this.onTextInputChange}
            onRaceCheckboxChange={this.onRaceCheckboxChange}
            optionChange={this.optionChange}
            signUp={this.signUp}
            onEnroll={this.onEnroll}
            onRecaptchaChange={this.onRecaptchaChange}
            onAcknowledge={this.onAcknowledge}
            hideSteps={
              this.props.location &&
              this.props.location.search &&
              this.props.location.search.indexOf("embed=true") !== -1
                ? true
                : false
            }
          />
        </div>
      </div>
    );
  }
}

export default EnrollmentController;
