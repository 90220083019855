import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "../App.css";
import "../bootstrap.css";
import { iLogo, list, logo } from "../../src/features/theme/icons";
import { environment } from "../constants";
import { checkForLettersOnly } from "../helpers/helper";
import LogoIcon from "./LogoIcon";

const menuItems = [
  { item: "Find Trials", url: "/" },
  { item: "Sponsors", url: "https://patientrials.com/" },
  { item: "About", url: "https://patientrials.com/about/" },
  // { item: "Blog", url: "/blog" },
];

const signUp = () => {
  localStorage.setItem("currentPage", window.location.href);
  window.location.href = `/enroll/${
    (environment === "PROD" && "STU01009") || (environment === "DEV" && "STU0001002") || (environment === "MAYO_CLINIC" && "STU01006")
  }`;
};

const Header = (props) => {
  const { showHeader } = props;
  const location = useLocation();
  const itemClick = (name) => {
    setClickedItem(name);
  };
  const [clickedMenuItem, setClickedItem] = useState("");
  const MenuItem = (i, url, itemName, activeState) => {
    return (
      <li
        key={i}
        onClick={() => itemClick(itemName)}
        className={
          itemName === clickedMenuItem ? "nav-item active" : activeState
        }
      >
        <a href={url} className="nav-link">
          {itemName}
        </a>
      </li>
    );
  };

  return (
    <div style={{ display: `${showHeader === false && "none"}` }}>
      <div className="menubar container-fluid">
        <nav className="navbar navbar-expand-lg">
          <a href="/">
            <img
                style={{height : 60}}
                className="navbar-brand logo"
                alt="logo"
                src={logo}
            ></img>
          </a>
          <button
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#menu"
          >
            <span className="navbar-toggler-icon">
              <img src={list} alt="header menu icon"></img>
            </span>
          </button>
          <div id="menu" className="collapse navbar-collapse">
            {environment === "DEV" ? (
              <p className="mb-0 mx-2" style={{ color: "darkorange" }}>
                DEV
              </p>
            ) : null}
            <ul className="navbar-nav ml-auto">
              {menuItems.map((menuitem, i) =>
                MenuItem(i, menuitem.url, menuitem.item, "nav-item")
              )}
            </ul>
            <button
              onClick={() => signUp()}
              rel="noopener noreferrer"
              className="signin btn btn-danger btn-lg ml-2 py-2 px-3"
              target="_blank"
            >
              SIGN UP
            </button>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
