import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  DataNotFound,
  Loading,
  RecruitmentSteps,
  ScreenFlow,
} from "../../components";
import { getStudyFromStudyNumber } from "../../helpers/pthelper";
import Template1 from "./template1";
import Template2 from "./template2";
import Template3 from "./template3";
import Template4 from "./template4";
import Template5 from "./template5";
import Template6 from "./template6";
import { log } from "../../helpers/helper";



const Preview = (props) => {
  const { studyId } = useParams();
  const location = useLocation();
  window.localStorage.removeItem("messages")
  let searchTerm =
    location.hasOwnProperty("search") === true
      ? location.search &&
        location.search.split("=") &&
        location.search.split("=")[1]
      : null;
  log(studyId, location, searchTerm.split("=")[1], "props");
  const [study, setStudy] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.addContext(window.location.href)
window.localStorage.setItem("enableChat", true)
    window.localStorage.removeItem("chat_status");
    window.localStorage.removeItem('acc_sid')
        window.localStorage.removeItem('flow_id')
        window.localStorage.removeItem("pt_sys_id");
        window.localStorage.removeItem("study_nct")
        window.localStorage.removeItem("study_sid")
        window.localStorage.removeItem("prospectID")
    getStudyFromStudyNumber(studyId)
      .then((gotStudy) => {
        log(gotStudy, "got study with num");
        let result =
          gotStudy && gotStudy.result !== undefined ? gotStudy.result : [];
        if (result && result.length > 0) {
          setStudy(result[0]);
          setLoading(true)
        } else {
          setStudy(null);
          setLoading(true)
        }
      })
      .catch((errStudy) => {
        setStudy(null);
        setLoading(true)
      });
  }, [studyId]);

  return (
    <div className="my-5 py-5">
      {loading === true ? 
      studyId && studyId.length > 0 ? (
        <div className="px-0 mx-0">
          {study !== null ? (
            (searchTerm === "1" && <Template1 trial={study} searchTerm={searchTerm} />) ||
            (searchTerm === "2" && <Template2 trial={study} searchTerm={searchTerm} />) ||
            (searchTerm === "3" && <Template3 trial={study} searchTerm={searchTerm} />) || 
            (searchTerm === "4" && <Template4 trial={study} searchTerm={searchTerm} />) ||
            (searchTerm === "5" && <Template5 trial={study} searchTerm={searchTerm} />) || 
            (searchTerm === "6" && <Template6 trial={study} searchTerm={searchTerm} />) || (
              <DataNotFound message={"Template not found"} />
            )
          ) : (
            <DataNotFound message={"Study details not found"} />
          )}
        </div>
      ) : (
        <DataNotFound message={"Study details not found"} />
      ) : <Loading /> }
    </div>
  );
};

export default Preview;
