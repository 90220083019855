import React from "react";

const AcknowlegementModal = (props) => {
  const { onAcknowledge, showAcknowledgement, signUp, loadingForModal } = props;
  return (
    <div className="acknowledgement_form container text-left p-0 pt-3">
      <div className="d-flex align-items-center justify-content-center">
        <button
          onClick={() => signUp()}
          className="gn_btn btn btn-danger btn-lg"
        >
          ENROLL
        </button>
      </div>
      <div
        style={{ display: showAcknowledgement ? "block" : "none" }}
        className="modal p-0 pt-5"
      >
        <div className="modal-content">
          {/* <div className="modal-header">
                  <button onClick={()=>onCloseModal()} type="button" className="close">&times;</button>
                </div> */}
          {loadingForModal ? (
            <div>
            <div style={{borderRadius : 0, overflowY: "scroll", height: 350}} className="acknowledgement_form container text-left">
              <h5 className="modal-title my-2" id="exampleModalLabel">
                Privacy Notice
              </h5>
              <p className="my-2">
                This Privacy Statement tells you how PatienTrials protects the
                privacy of personal information that you may provide to us. Our
                website, our database, and our mobile application,
                PatienTrials®, are built with all the latest privacy and
                security features that are required to keep patient information
                safe. We follow all the guidelines of both healthcare technology
                and research technology security features without compromising
                beautiful design and keeping your data secure is our highest
                priority.
              </p>
              <h3 className="my-2">Summary</h3>
              <p>
                PatienTrials may collect anonymous computer information from you
                on our website. We collect personally identifiable information
                from you—with your consent– when you complete an interest form
                or eligibility questionnaire for specific trials. In all cases,
                your personally identifiable information is kept confidential.
                We only share this information with our partners and sponsors
                who are working with us on a specific clinical trial. All other
                information collected from you may be used and shared with third
                parties on an anonymous or aggregated basis only. Your personal
                information is stored in our databases, and if you participate
                in a trial using PatienTrials®, your data will be stored there
                as required by the trial, and we will contact you as required by
                the trial and in the manner, you agreed to upon enrollment. In
                addition to participation in a specific trial, we may contact
                you from time to time about other clinical trials that may be of
                interest to you. You can opt out from this communication at any
                time.
              </p>
              <h3 className="my-2">Purpose of Privacy Notice</h3>
              <p>
                The purpose of this privacy statement is to explain what
                PatienTrials does with personally identifiable information that
                you provide to us, such as your name, address, age, and
                information relating to your medical conditions. We want you to
                know how this information will be protected, under what
                circumstances we may share it and with whom, and for what
                purposes.
              </p>
              <h3 className="my-2">
                Reason for Collection of Your Information
              </h3>
              <p>
                There is no requirement to register to view the PatienTrials
                website. You can freely visit many of our web pages without
                telling us who you are. However, other activities possible
                through our site, such as completing an participate form for
                specific clinical trials, require that you provide your personal
                details, including but not limited to information about your
                medical history, demographics, email address, name, and other
                information. This allows us to process and/or send you
                information that you request about specific clinical trials. If
                selected to participate in a clinical trial, further
                registration is required to use the PatienTrials® clinical
                trials mobile application. If you are selected to participate in
                a PatienTrials trial, you will receive an invitation to enroll
                in PatienTrials® as a user by setting an email address and a
                password, and you may be asked to enter additional information
                specific to the clinical trial in which you are enrolling.
              </p>
              <h3 className="my-2">Personal Information Collected From You</h3>
              <p>
                The type of information collected from you will depend on
                whether you are registering for a general interest list, or are
                indicating interest in a specific trial. The information that we
                may ask you to provide may include your first and last name,
                your mailing address, your age, birth date, gender, e-mail
                address, and information about your medical history.
                PatienTrials seeks to limit the collection and processing of
                personal and medical information to the minimum for what is
                necessary to fulfill the purposes for which it is to be used.
              </p>
              <h3 className="my-2">
                Consolidation of Information Collected From You
              </h3>
              <p>
                If you indicate interest in more than one study, or sign up on
                multiple pages, we may combine your personally identifiable
                information as well as any anonymous computer information
                collected (see below) and store it collectively. This helps us
                keep track of all of your information in one place, and helps us
                provide information to you based on a more informed review of
                your requests and medical conditions of interest.
              </p>
              <h3 className="my-2">Data Integrity</h3>
              <p>
                PatienTrials takes all reasonable steps to ensure that personal
                data collected is reliable for its intended use, accurate,
                complete, and current.
              </p>
              <h3 className="my-2">
                Collection of Anonymous Computer Information
              </h3>
              <p>
                When you visit the PatienTrials website, we do not collect any
                personally identifiable information about you without your
                permission. However, we do collect anonymous information about
                you if your computer is set to allow that type of collection.
                (You can turn off the ability to collect some of this
                information on most computers.) This type of anonymous
                information allows us to evaluate customer interest in our
                websites and perform other market research activities, such as
                analyzing customers' browsing habits on our site. It also allows
                us to track customers who may come to our websites based on
                website advertisements about PatienTrials services. (For those
                who are interested in the details, some of the typical computer
                techniques we may use are "cookies", "internet tags" which are
                also called "action tags", "single-pixel GIFs", clear GIFs,
                "invisible GIFs, and "1-by-1 GIFs). If you would like more
                information about web tags and cookies associated with on-line
                advertising or how to opt out of third party collection of
                information, please visit the Network Advertising Initiative
                website (https://www.networkadvertising.org), an independent
                website not sponsored by or affiliated with PatienTrials.
              </p>
              <h3 className="my-2">
                Consent to Provide Your Information to PatienTrials
              </h3>
              <p>
                We must obtain your consent to collect your personally
                identifiable information when you complete an interest form
                regarding a specific trial, or enroll in PatienTrials® to
                participate in one. In doing so, we also explain, for that
                trial, what you are registering to receive and how we plan to
                contact you and use your personal information. Enrolling in
                PatienTrials® for the purpose of participating in a specific
                trial also includes completing an informed consent for
                participation that addresses the data collected and stored for
                that trial. Once you are enrolled for a specific trial, your
                data pertinent to that trial will be retained. We offer you the
                option of discontinuing your consent ("opting out" or
                "unsubscribing") from future communication if you later decide
                that you no longer want to participate in the specific trial or
                receive additional information from us. If we wish to use this
                information for purposes incompatible with those for which the
                data was initially collected, we will inform you first and offer
                an opportunity to opt out of the secondary use.
              </p>
              <h3 className="my-2">
                Sharing Your Information With Third Parties
              </h3>
              <p>
                PatienTrials will not share your personal information with third
                parties unless we have obtained your express consent to do so.
                You provide your consent when you click 'submit' at the end of
                the participation form or enrollment process. Your personal
                information may thus be shared with selected personnel and
                partners working on this clinical trial. PatienTrials requires
                third parties to whom it discloses personal data to protect that
                information using substantially similar standards to those
                followed by PatienTrials. We also require that they do not use
                your personal information for any separate use that is not
                specifically for the execution of the clinical trial and
                authorized by PatienTrials. There may also be instances where
                PatienTrials may be required to share your information with
                third parties who have not been retained by us (directly or
                indirectly), such as with health authorities, during inspections
                or audits, or as ordered or directed by courts or other
                governmental agencies. In these cases, unless otherwise required
                by law, the information shared is de-identified. Adverse Event
                reports may be forwarded to the relevant health authorities.
              </p>
              <h3 className="my-2">Security of Your Information</h3>
              <p>
                We use appropriate technical, administrative and physical
                safeguards to protect data that pertains to you from loss,
                misuse or alteration. In addition, we take steps to ensure that
                our safeguards take into account new known threats. Of course,
                no organization can guarantee the absolute security of personal
                information. You should keep in mind that Internet
                transmissions, particularly email transmissions, are never 100%
                secure or error-free. Accordingly, we also caution you to guard
                your own computer and password against unauthorized access by
                others.
              </p>
              <h3 className="my-2">
                Withdrawal of your consent to participate in research
              </h3>
              <p>
                If you have enrolled in PatienTrials® in order to participate in
                a specific research study, you may withdraw your consent to
                participate at any time by emailing info@patientrials.com. Any
                research involving your data that has already been performed or
                published prior to our receipt of your request will not be
                reversed, undone, or withdrawn.
              </p>
              <h3 className="my-2">Updated PatienTrials Privacy Notices</h3>
              <p>
                This Privacy Statement becomes effective on March 25, 2018.
                PatienTrials may update this Statement from time to time. We
                encourage you to review our Privacy Statement periodically.
              </p>
              <h3 className="my-2">How to Contact Us</h3>
              <p>
                If you have any questions about our Privacy Statement please
                send us your inquiry to info@patientrials.com and we will get
                back to you shortly after.
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-end">
                    <button
                      onClick={() => onAcknowledge("false")}
                      type="button"
                      className="dt_btn btn btn-lg my-2 mx-2"
                    >
                      DENY & CANCEL
                    </button>
                    <button
                      onClick={() => onAcknowledge("true")}
                      type="button"
                      className="gn_btn btn btn-lg my-2 mx-2"
                    >
                      I ACKNOWLEDGE & ACCEPT
                    </button>
                </div>
            </div>
          ) : (
            <h3 className="mx-auto text-center mb-0">loading ...</h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default AcknowlegementModal;
