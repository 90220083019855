import React from 'react';
import { AcknowledgementModal, Participant } from '../features/enroll';
import { thirdStep } from '../features/theme/icons';
import { countries, log, validateEmail } from '../helpers/helper';
import { documentUpload, getTrialId } from '../helpers/pthelper';
import DocumentUploadModal from './DocumentUploadModal';
import Loading from './Loading';
import axios from "axios"
import { environment, Recaptcha_site_key } from '../constants';
import ReCAPTCHA from "react-google-recaptcha";



class DocumentUpload extends React.Component{
    constructor(props){
        super();
        this.race={}
        this.state={
          trial : null,
          domain : "",
          studySysId : "",
          nctNumber : "",
          show_patient_id : false ,
          isParticipantEnrolled : false,
          showModal : false,
          showAcknowledgement : false,
            file: "",
            fileName : "",
            fileType : "",
            output : "",
            isLoading : "" ,
            loadingForModal : false,
            showDocumentModal : false,
            loadingForDocumentModal : false,
            showAcknowledgement :false,
            patientSysId: "",
            country:"",
            city:"",
            zipcode:"",
            firstname:"",
            lastname:"",
            email:"",
            gender:"",
            dob:"",
            phone:"",
            smokingstatus:"",
            primarylanguage:"",
            race:"",
            patient_id:"",
            currentmedication:"",
            healthcondition:"",
            familyhistory:"",
            conditions : "",
            medications : "",
            recaptcha_response : "",
        }
    }
    

    onTextInputChange = (e) => {
      //log(`${e.target.name}, ${e.target.value}, ${e.target}`);
      this.setState({ [e.target.name]: e.target.value }, () => {
      if (
          this.state.email !== null ||
          this.state.email !== "" ||
          this.state.email !== undefined
        ) {
          let checkEmail = validateEmail(this.state.email);
          if (checkEmail === false) {
            this.setState({ showEmailError: true });
          } else if (checkEmail === true) {
            this.setState({ showEmailError: false });
          }
        }
      })
    };

    submitAnswer = (question) =>{
      return new Promise((resolve , reject)=>{
        return axios
              .get(
                `/backend/submit?answer=${
                  question.answer
                }&sys_id=${question.sys_id}&participant_id=${this.state.patientSysId}`
              )
              .then((suc) => {
                log(suc, "submitted answers");
                return
              })
              .catch((e) => {
                log(e , 'err submitting prescreening responses')
                alert("Error submitting responses")
              });
      })
    }

    submitAnswers = () =>{
      let questions = window.localStorage.getItem("prescreening");
      log(JSON.parse(questions) , 'after fetching')
      let parsedQuestions = JSON.parse(questions)
      if(parsedQuestions !== null){
        if(parsedQuestions && parsedQuestions.length > 0){
          parsedQuestions.map((ques) => {
            //log(this.state[ques.sys_id], "for submitting");
            this.submitAnswer(ques).then(submit=>{
              log(submit , 'submitted answer')
            }).catch(errSubmit=>{
              log(errSubmit , 'err while submitting answers')
            })
          });
          // setTimeout(()=>{
          //   this.shareRecords()
          // },3000)
        }else{
          log(questions , 'no questions found');
          return;
        }
      }else{
        log(questions , 'err fetching prescreening data');
        alert("Error fetching prescreening data")
      }
    }

    handleAttachmentChange = (e) => {
        let file = e.target.files[0];
        let user_file_name = e.target.files[0].name;
        let fileName = user_file_name;
        let fileType = e.target.files[0].type;
        log(file, fileType, e.target.files[0].name, "file details");
        this.setState({
          file: file,
          fileName: fileName,
          fileType: fileType,
        });
      };

      setCountry =(country) =>{
        if(country && country.length > 0){
          if((country === "US") || 
        (country === "USA") || 
        (country === "U.S") || 
        (country === "U.S.A") || 
        (country === "U.S.A.") || 
        (country === "United States of America") ||
        (country === "United States") || 
        (country === "us") || 
        (country === "usa") || 
        (country === "u.s") || 
        (country === "u.s.a") || 
        (country === "u.s.a.") || 
        (country === "united states of america") ||
        (country === "united states")){
          return "UNITED STATES"
        } else {
          return country
        }
        } else{
          return ""
        }
      }
  
      setLanguage = (language) =>{
        if(language && language.length > 0){
          if((language === "ENGLISH") || 
        (language === "english") || 
        (language === "EN") || 
        (language === "en") || 
        (language === "ENG") || 
        (language === "eng")){
          return "en"
        } else if(
          (language === "SPANISH") || 
          (language === "spanish") || 
          (language === "SP") || 
          (language === "sp") 
          ){
          return "sp"
        } else if((language === "VIETNAMESE") || 
        (language === "vietnamese") || 
        (language === "VT") || 
        (language === "vt") ){
          return "vt"
        }
        } else{
          log(language , 'no input language received');
          return ""
        }
      }

      onRaceCheckboxChange = (e, qid) => {
        if (e.target.checked) {
          //log(`${e.target.checked}, ${e.target.value}, "checking race inputs"`);
          if (e.target.type === "checkbox") {
            this.race.hasOwnProperty("race")
              ? this.race["race"].push(e.target.value)
              : (this.race["race"] = [e.target.value]);
          } else {
            this.race.hasOwnProperty("race")
              ? this.race["race"].push(e.target.value)
              : (this.race["race"] = [e.target.value]);
          }
        } else {
          if (e.target.type === "checkbox") {
            //log(`${e.target.checked}, ${e.target.value}, "checking race inputs"`);
            if (this.race.hasOwnProperty("race")) {
              let arr = this.race["race"].indexOf(e.target.value);
              this.race["race"].splice(arr, 1);
            }
          }
        }
        //log(`${this.race}, "in race oncheckbox"`);
        this.setState({ race: this.race["race"].toString() });
      };
  

      mapProfie =() =>{
        let profile = this.state.output;
        if((profile !== null) || (profile !== undefined) || (profile !== {})){
            this.setState({
              country: "UNITED STATES" ,
              city: ((profile && profile.city) || "") ,
              zipcode: ((profile && profile.zipcode) || "") ,
              firstname: ((profile && profile.first_name) || ""),
              lastname: ((profile && profile.last_name) || ""),
              email: ((profile && profile.email_id) || ""),
              gender: ((profile && profile.gender) || (profile && profile.gender == "Male" ? "male" : "") || (profile && profile.gender === "Female" ? "female" : "") || ""),
              dob: ((profile && profile.dob) || ""),
              phone: ((profile && profile.contact_number) || ""),
              conditions : ((profile && profile.conditions) || "") , 
              medications : ((profile && profile.medications) || "") ,
              primarylanguage: this.setLanguage(profile && profile.language && profile.language !==undefined ? profile.language : "")
            })
        }else{
          log(profile , 'no records found');
          alert("Error fetching details from uploaded document")
        }
      }
  

      onSubmit =() =>{
        this.setState({ isLoading : false })
        let file = this.state.file
        if((file === "") || (file === null) || (file === " ") || (file === undefined)){
            log(file, 'no file input was received')
        }else{
            documentUpload( file , this.state.fileName).then(success=>{
                log(success.result , 'success');
                this.setState({ output : success.result , isLoading : true} ,()=>{
                    this.mapProfie()
                })
            }).catch(err=>{
                log(err , 'err submitting file')
                this.setState({ output : null})
            })
        }
      }

      onRecaptchaChange = (value) =>{
        log(value,'rv')
        this.setState({ recaptcha_response : value})
      }

      componentDidMount(){
        let { studyId } = this.props.match.params;
        if(studyId && studyId.length > 0){
          getTrialId(studyId).then(success=>{
            log(success , 'got study details');
            this.setState(
              {
                trial: success && success.trial,
              },
              () => {
                this.setState(
                  {
                    domain: this.state.trial && this.state.trial.domain,
                    studySysId: this.state.trial && this.state.trial.study_sys_id,
                    nctNumber: this.state.trial && this.state.trial.nct_number,
                    show_patient_id: (this.state.trial && this.state.trial.show_patient_id) || "false"
                  })
                })
          }).catch(errStudy=>{
            log(errStudy , 'err fetching study details')
          })
        } else{
          log(studyId , 'cannot get study id');
          alert("Error occured in fetching study details")
        }
      }

      signUp = () => {
        const {
          city,
          firstname,
          lastname,
          email,
          gender,
          phone,
          dob,
          race,
          smokingstatus,
          primarylanguage,
          studySysId,
          country,
          zipcode,
          stateterritory,
          timezone,
          patient_id,
          nctNumber,
          currentmedication,
          healthcondition,
          familyhistory,
          domain,
          criteria,
          currentpage,
          showModal,
          participant_id,
          recaptcha_response
        } = this.state;
        let checkEmptyValues = [
          firstname,
          lastname,
          city,
          country,
          zipcode,
          email,
          gender,
          phone,
          dob,
          smokingstatus,
          primarylanguage,
          race,
          healthcondition,
          recaptcha_response
        ];
        let check = checkEmptyValues.filter(
          (item) => item === "" || null || undefined
        );
        if (check && check.length > 0) {
          //log(`${check}, "check"`);
          alert("please enter all required values");
        } else {
          //log("do further");
          this.setState({ showAcknowledgement: true });
          let request_body = {
            recaptcha_response : recaptcha_response,
            preferred_name: firstname,
            last_name: lastname,
            gender: gender,
            date_of_birth: dob,
            email: email,
            phone_number: phone,
            race: race.toString(),
            study: studySysId,
            language: primarylanguage,
            country: country,
            zip_code_or_city: zipcode,
            state_territory: stateterritory,
            time_zone: timezone,
            domain: domain,
            patient_id: patient_id,
            nct_number : nctNumber,
            current_medications: currentmedication,
            health_condition: healthcondition,
            relevant_family_history: familyhistory,
            smoking_status: smokingstatus,
            guest_id: participant_id,
            other_information:
              criteria === null ? currentpage : criteria + currentpage,
            prescreening_qa : ""
          };
          environment === "DEV" ? request_body["sys_domain"] = domain : log("PROD")
          //log(`${request_body}, "request body"`);
          //log(request_body, 'body');
          axios
            .post("/backend/participant", request_body)
            .then((success) => {
              //log(`${success.status},'then'`);
              if (
                (success && success.status === 201) ||
                (success && success.status === 200)
              ) {
                //log(`${success && success.data.result}, "created participant"`);
                this.setState(
                  {
                    patient_id:
                      success &&
                      success.data &&
                      success.data.pt_id,
                  },
                  () => {
                    this.setState({ showModal: !showModal });
                    let patientSysId =
                      success &&
                      success.data &&
                      success.data.pt_sys_id;
                    let uName = success &&
                    success.data &&
                    success.data.u_id;
                    this.setState({ patientSysId: patientSysId, pt_uname: uName  }, () => {
                      this.setState({ participant_id : patientSysId },()=>{
                        this.submitAnswers()
                      })
                      this.setState({ showModal: false, loadingForModal: true });
                      //this.setState({ isParticipantEnrolled: true });
                    });
                  }
                );
              } else if (
                success &&
                success.data &&
                success.data.failure === "failure"
              ) {
                let participantError =
                  success &&
                  success.data &&
                  success.data.error &&
                  success.data.error.message;
                alert(participantError + "Error while creating participant");
                this.setState({
                  loadingForModal: false,
                  showAcknowledgement: false,
                });
              } else {
                //log(`${success.status},'then'`);
                alert("Error while creating participant");
                this.setState({
                  loadingForModal: false,
                  showAcknowledgement: false,
                });
              }
            })
            .catch((errSuccess) => {
              // log(
              //   `${errSuccess},${errSuccess.status}, "error creating participant"`
              // );
              //log(errSuccess,errSuccess.response ,'err while creating participant');
              if(errSuccess && errSuccess.response && errSuccess.response.status === 400){
                alert(
                  "Error while creating participant"
                );
                this.setState({ loadingForModal: false, showAcknowledgement: false });
              }else if(errSuccess && errSuccess.response && errSuccess.response.status === 403){
                alert(
                  "Error while creating participant looks like email already exists"
                );
                this.setState({ loadingForModal: false, showAcknowledgement: false });
              }
            });
        }
      };
    
      onAcknowledge = () => {
        axios
          .post("/backend/acknowledge", {
            sysId: this.state.patientSysId,
            acknowledged: "true",
          })
          .then((successAck) => {
            //log(`${successAck && successAck.data}, "on acknowledge"`);
            this.setState({
              isParticipantEnrolled: true,
              showModal: false,
              showAcknowledgement: false,
            },()=>{
              window.localStorage.setItem("chat_status" , "started");
            window.localStorage.setItem("prospectID" , this.state.patient_id );
            window.localStorage.setItem("f_name" , this.state.firstname );
            window.localStorage.setItem("city" , this.state.city);
            window.localStorage.setItem("dob" , this.state.dob );
            window.localStorage.setItem("gender" , this.state.gender );
            window.location.href = `/thankyou/${this.state.patient_id}`
            });
            
          })
          .catch((errSuccessAck) => {
            //log(`${errSuccessAck}, "error while acknowledgement"`);
            this.setState({
              isParticipantEnrolled: false,
              showModal: false,
              showAcknowledgement: false,
            });
          });
      };
    

      
 
    render(){ 
        
        return(
        <div className='my-5 py-5 container'>
            {(this.state.isLoading === "" && <div>
            <div className='mt-4'>
                <h5>Please upload your document</h5>
                <input
                        onChange={this.handleAttachmentChange}
                        accept=".png, .jpg, .pdf, .xlsx, .ods, .docx, .txt"
                        type="file"
                        name="upload_document"
                ></input>
            </div>
            <button className='mt-3 btn btn-primary btn-small' onClick={()=>this.onSubmit()}>submit</button>
            </div>) || 
            (this.state.isLoading === false ? <Loading /> : 
            <div>
              <div>
                  <div className="row my-5">
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                      <div className="textinput form-group">
                        <p>{"Country*"}</p>
                        <select
                          onChange={this.onTextInputChange}
                          className="p-2 form-control"
                          placeholder={"Select"}
                          name={"country"}
                          value={this.state.country}
                        >
                          {countries.map((nation, i) => (
                            <option key={i} value={nation}>
                              {nation}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                      <div className="textinput form-group">
                        <p>{"City*"}</p>
                        <input
                          type="text"
                          className="p-2 form-control"
                          name={"city"}
                          onChange={this.onTextInputChange}
                          value={this.state.city}
                          placeholder={"Enter"}
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                      <div className="textinput form-group">
                        <p>{"Zipcode*"}</p>
                        <input
                          type="number"
                          className="p-2 form-control"
                          name={"zipcode"}
                          onChange={this.onTextInputChange}
                          value={this.state.zipcode}
                          placeholder={"Enter"}
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="row my-5">
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                      <div className="textinput form-group">
                        <p>{"First Name*"}</p>
                        <input
                          type="text"
                          className="p-2 form-control"
                          name={"firstname"}
                          onChange={this.onTextInputChange}
                          value={this.state.firstname}
                          placeholder={"Enter"}
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                      <div className="textinput form-group">
                        <p>{"Last Name*"}</p>
                        <input
                          type="text"
                          className="p-2 form-control"
                          name={"lastname"}
                          onChange={this.onTextInputChange}
                          value={this.state.lastname}
                          placeholder={"Enter"}
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                      <div className="textinput form-group">
                        <p>{"Email*"}</p>
                        <input
                          type="email"
                          className="p-2 form-control"
                          name={"email"}
                          onChange={this.onTextInputChange}
                          value={this.state.email}
                          placeholder={"Enter"}
                        ></input>
                        {this.state.showEmailError ? (
                          <p style={{ color: "red" }} className="mb-0">
                            Please enter valid email
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row my-5">
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                      <div className="textinput form-group">
                        <p>{"Gender*"}</p>
                        <select
                          onChange={this.onTextInputChange}
                          className="pl-2 form-control"
                          placeholder={"Select"}
                          name={"gender"}
                          value={this.state.gender}
                        >
                          {[
                            { title: "Please Select", value: " " },
                            { title: "Male", value: "male" },
                            { title: "Female", value: "female" },
                          ].map((selectoption, i) => (
                            <option key={i} value={selectoption.value}>
                              {selectoption.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                      <div className="textinput form-group">
                        <p>{"Date of Birth*"}</p>
                        <input
                          type="date"
                          className="p-2 form-control"
                          name={"dob"}
                          onChange={this.onTextInputChange}
                          value={this.state.dob}
                          placeholder={"DD/MM/YYYY"}
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                      <div className="textinput form-group">
                        <p>{"Primary Contact Number*"}</p>
                        <input
                          type="number"
                          className="p-2 form-control"
                          name={"phone"}
                          onChange={this.onTextInputChange}
                          value={this.state.phone}
                          placeholder={"Enter"}
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                      <div className="textinput mt-2 form-group">
                        <p>{"Smoking Status*"}</p>
                        <select
                          onChange={this.onTextInputChange}
                          className="p-2 form-control"
                          placeholder={"Select"}
                          name={"smokingstatus"}
                          value={this.state.smokingstatus}
                        >
                          {[
                            { title: "Please Select", value: " " },
                            { title: "Current", value: "Current" },
                            { title: "Ex", value: "Ex" },
                            { title: "Never", value: "Never" },
                          ].map((selectoption, i) => (
                            <option key={i} value={selectoption.value}>
                              {selectoption.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                      <div className="textinput mt-2 form-group">
                        <p>{"Primary Language*"}</p>
                        <select
                          onChange={this.onTextInputChange}
                          className="p-2 form-control"
                          placeholder={"Select"}
                          name={"primarylanguage"}
                          value={this.state.primarylanguage}
                        >
                          {[
                            { title: "Please Select", value: " " },
                            { title: "English", value: "en" },
                            { title: "Spanish", value: "sp" },
                            { title: "Vietnamese", value: "vt" },
                          ].map((selectoption, i) => (
                            <option key={i} value={selectoption.value}>
                              {selectoption.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {this.state.show_patient_id === "true" ? 
                      <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                          <div className="textinput mt-2 form-group">
                            <p>{"Patient ID"}</p>
                            <input
                            type = "text"
                              onChange={this.onTextInputChange}
                              className="p-2 form-control"
                              placeholder={"Enter Patient ID"}
                              name={"patient_id"}
                              value={this.state.patient_id}
                            >
                            </input>
                          </div>
                    </div>
                    : null}
                  </div>
                  <div className="textinput my-3">
                    <p className="mb-0">
                      How do you identify your race or ethnicity? *
                    </p>
                    {[
                      {
                        title: "American Indian or Alaskan Native",
                        value: "American Indian or Alaskan Native",
                      },
                      {
                        title: "Asian or Pacific Islander",
                        value: "Asian or Pacific Islander",
                      },
                      {
                        title: "Black or African American",
                        value: "Black or African American",
                      },
                      {
                        title: "Hispanic or Latino",
                        value: "Hispanic or Latino",
                      },
                      {
                        title: "White or Caucasian",
                        value: "White or Caucasian",
                      },
                      {
                        title: "(Mixed Race) American Indian or Alaskan Native",
                        value: "(Mixed Race) American Indian or Alaskan Native",
                      },
                      {
                        title: "(Mixed Race) Asian or Pacific Islander",
                        value: "(Mixed Race) Asian or Pacific Islander",
                      },
                      {
                        title: "(Mixed Race) Black or African American",
                        value: "(Mixed Race) Black or African American",
                      },
                      {
                        title: "(Mixed Race) Hispanic or Latino",
                        value: "(Mixed Race) Hispanic or Latino",
                      },
                      {
                        title: "(Mixed Race) White or Caucasian",
                        value: "(Mixed Race) White or Caucasian",
                      },
                    ].map((raceItem, i) => (
                      <div key={i} className="promo_answer">
                        <input
                          style={{ height: "auto", width: "auto" }}
                          className="mx-1 mt-2"
                          type={"checkbox"}
                          onChange={(e) =>
                            this.onRaceCheckboxChange(e, raceItem.title)
                          }
                          name={raceItem.value}
                          value={raceItem.value}
                        />
                        <span className="mx-2">{raceItem.title}</span>
                      </div>
                    ))}
                  </div>
                  <div className="">
                    <div className="pl-0">
                      <div className="textinput form-group">
                        <p>{"Current Medication"}</p>
                        <textarea
                          className="col-12 p-2 form-control"
                          type="text"
                          name={"currentmedication"}
                          onChange={this.onTextInputChange}
                          value={this.state.currentmedication}
                          placeholder={"Enter"}
                        ></textarea>
                      </div>
                    </div>
                    <div className="pl-0">
                      <div className="textinput form-group">
                        <p>{"Health Condition"}</p>
                        <textarea
                          className="col-12 p-2 form-control"
                          type="text"
                          name={"healthcondition"}
                          onChange={this.onTextInputChange}
                          value={this.state.healthcondition}
                          placeholder={"Enter"}
                        ></textarea>
                      </div>
                    </div>
                    <div className="pl-0">
                      <div className="textinput form-group">
                        <p>{"Relevant Family History"}</p>
                        <textarea
                          className="col-12 p-2 form-control"
                          type="text"
                          name={"familyhistory"}
                          onChange={this.onTextInputChange}
                          value={this.familyhistory}
                          placeholder={"Enter"}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div>
                      <ReCAPTCHA
                        sitekey={Recaptcha_site_key}
                        onChange={this.onRecaptchaChange}
                        // ref={ref}
                      />
                  </div>
                  {this.state.recaptcha_response === "" ? <p style={{color: 'red', fontSize: 13}}>Please fill recaptcha</p> : null}
                  <div className="my-2 d-flex align-items-center justify-content-end">
                    <AcknowledgementModal
                      
                      loadingForModal={this.state.loadingForModal}
                      onCloseModal={this.onCloseModal}
                      signUp={this.signUp}
                      onAcknowledge={this.onAcknowledge}
                      showAcknowledgement={this.state.showAcknowledgement}
                    />
                  </div>
                </div>
            </div>
            )}
        </div>)
    }
}

export default DocumentUpload