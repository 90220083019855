import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  fetchVirtualAppointments,
  getConferenceStatus,
} from "../helpers/pthelper";
import Loading from "./Loading";
import { useParams } from "react-router-dom";
import { log } from "../helpers/helper";

const Conference = () => {
  const { patient, appointmentId } = useParams();
  const [appointment, setAppointment] = useState("");
  const [checkForMeeting, setCheckForMeeting] = useState("");
  const [loading, setLoading] = useState(false);

  const getProspectName = (link) =>{
    if(link && link.length > 0){
      let splitName = link && link.split("?fullName=");
      log(splitName , 'splitName')
      if(splitName && splitName.length > 0){
        let name = splitName[1].split("&");
        return name && name.length > 0 ? name[0] : "Anonymous"
      }
    } else{
      log(link , 'no link found while fetching name');
      return "Anonymous"
    }
  }

  useEffect(() => {
    fetchVirtualAppointments(patient, appointmentId)
      .then((appt) => {
        log(appt, "got appt");
        let result_appt = appt;
        if (result_appt !== undefined) {
          setAppointment(result_appt);
          
          log(result_appt.meeting_active_check_link, "meeting link");
          setInterval(() => {
            getConferenceStatus(result_appt.meeting_active_check_link)
              .then((status) => {
                log(status, "succ");
                let result = status.response;
                let code = result.returncode[0];
                let conf_status = result.running[0];
                if (conf_status !== undefined) {
                  setCheckForMeeting(
                    (conf_status === "false" && false) ||
                      (conf_status === "true" && true)
                  );
                } else {
                  setCheckForMeeting(null);
                }
              })
              .catch((errStatus) => {
                log(errStatus, "err fetching status");
              });
          }, 4000);
          setLoading(true);
        } else {
          log(result_appt, "appt undefined");
          setAppointment(null);
          setLoading(true);
        }
      })
      .catch((errAppt) => {
        log(errAppt, "err fetching appt");
        setAppointment(null);
      });
  }, []);

  const goToMeeting = () => {
    window.location.href = appointment.video_call_link;
  };

  return (
    <div className="my-5 py-5 mx-auto text-center">
      <h6 className="mt-4">Conference Waiting Room</h6>
      {loading === false ? (
        <Loading />
      ) : (
        <div>
          <h6>{appointment.name || "Anonymous"}</h6>
          <p style={{ fontWeight : 'bold' }} className="mb-0">{patient}</p>
          <p style={{ fontWeight : 'bold' }} className="mb-0">{getProspectName(appointment.video_call_link)}</p>
          {checkForMeeting === true ? (
            goToMeeting()
          ) : (
            <h4 className="mb-0 mt-5 pt-5">
              {(checkForMeeting === "" && "Loading ...") ||
                (checkForMeeting === null &&
                  "Meeting status cannot be determined") ||
                (checkForMeeting === false &&
                  "Please wait staff member needs to start the conference") ||
                (checkForMeeting === true &&
                  "Meeting started ... Re directing")}
            </h4>
          )}
        </div>
      )}
    </div>
  );
};

export default Conference;
